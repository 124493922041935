import { EntityPCN, PCNAssignmentUser, PCNList, PCNMarkerCoords } from './../shared/domain/schedules-routes-tab';
import * as CryptoJS from 'crypto-js';
import { 
    action,
    computed,
    observable,
    makeObservable,
    configure as mobxConfigure
} from 'mobx';
// import Cookies from 'universal-cookie';
import { HttpConnectorAdapter } from '../adapters/http-connector-adapter';
import { SearchResults, ReportResults, SearchResultsUser } from '../adapters/report-base-adapter';
import { NavigationListItem } from '../components/navigation-list';
import { COOKIE_NAMES } from '../constants';
import {
    AuthenticationType,
    LoginAuthorization,
} from '../models/login-authorization';
import { Marker } from '../models/marker';
import { RegisteredUser, UserPermissions } from '../models/registered-user';
import {
    Report,
    ReportComment,
    ReportDetail,
    ReportModule,
    ReportModuleGroup,
    ReportStatus,
    submitVocWidgetData,
    VocFeedback,
    WidgetDataValues,
} from '../models/report';
import { LoginWizardSteps } from '../shared/components/login-wizard';
import {
    Account,
    AnalyticsTab,
    Folder,
    Floorplan,
    User,
    UserTeam,
    SchedulesTab,
    Schedules,
    SchedulesJobStatus,
    UploadScheduleDetail,
    TasksTab,
    Tasks,
    AnnotationData,
    Annotations,
    TaskFilterParameters,
    ScheduleFilterParameters,
    SchedulesRoutesTab,
    ScheduleRoute,
    ScheduleRow,
    PCNRoutes,
    PCNFiltersParameters
} from '../shared/domain';
import { EnumerationHelper } from '../shared/utils';
import {
    ButtonType,
    CCDatePickerDateRange,
} from './../components/cc-date-picker/cc-date-picker';
import { RootStoreBase } from './root.store.base';
import { ROUTE_NAMES } from './routes';
import { CustomFilterValues, CustomFiltersParameters, FolderFilterValues } from '../shared/domain/completion';
import { GridRowsProp } from '@mui/x-data-grid';
import { JobCreationResult, JobErrorResult, JobStatusResult, RunningJobs } from '../models/jobs';
import memoize from 'memoize-one';
import { RenderTree } from '../shared/components/node-tree';
import { ValidAgGridRowModel } from '../shared/components/ag-grid-list';
import Bugsnag from '@bugsnag/js';

// Since MobX version 6 actions are enforced by default
// Need to disable warnings with configure method
mobxConfigure({
    enforceActions: 'never',
});

const SESSION_EXPIRED_ERROR_TEXT =
    'The current session is expired. Please reload the page.';

//#region encryption stuff
const getAESKey = (): string => {
    const password = '3hdqN0GrzSKK3';
    const passwordBytes = CryptoJS.enc.Utf16LE.parse(password);
    const sha1Hash = CryptoJS.SHA1(passwordBytes);

    const sha1HashToBase64 = sha1Hash.toString(CryptoJS.enc.Base64);

    // we are getting only the first 8 chars for actual key generation
    const sha1HashToBase64Short = sha1HashToBase64.substring(0, 8);

    const AESKey = CryptoJS.enc.Utf16.parse(sha1HashToBase64Short);
    return AESKey;
};

const encryptText = (plainText: string): string => {
    const aesKey = getAESKey();

    // Note that we are being lazy and the encryption key itself
    // is used as the initialization vector for AES
    const encryptedData = CryptoJS.AES.encrypt(plainText, aesKey, {
        iv: aesKey,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    // cryptHex will contain the cipher text in base64
    const encryptedText = encryptedData.toString();
    return encryptedText;
};

const decryptText = (encryptedText: string): string => {
    const aesKey = getAESKey();

    const decryptedData = CryptoJS.AES.decrypt(encryptedText, aesKey, {
        iv: aesKey,
    });

    const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedText;
};
//#endregion

//#region Local storage
const getItemFromLocalStorage = (
    name: string,
    isSession = false
): string | null => {
    if (!name) {
        return null;
    }

    if (isSession) {
        return sessionStorage.getItem(name);
    }

    return localStorage.getItem(name);
};

const setItemToLocalStorage = (
    name: string,
    value: string,
    isSession = false
) => {
    if (!name) {
        return;
    }

    if (isSession) {
        sessionStorage.setItem(name, value);
    } else {
        localStorage.setItem(name, value);
    }
};

const deleteItemFromLocalStorage = (name: string, isSession = false) => {
    if (!name) {
        return;
    }

    if (isSession) {
        sessionStorage.removeItem(name);
    } else {
        localStorage.removeItem(name);
    }
};
//#endregion

// const cookiesContext = new Cookies();
// const setCookie = (cookieName: string, cookieValue: any) => {
//     const expires = new Date((new Date()).getTime()+(12*3600000)); // Expires after 12 hours
//     const COOKIE_OPTIONS = {
//         path: '/',
//         expires
//     };

//     clearCookie(cookieName);
//     cookiesContext.set(cookieName, cookieValue, COOKIE_OPTIONS);
// }

// const clearCookie = (cookieName: string) => {
//     cookiesContext.remove(cookieName, {path: '/'});
// }

interface LoginResponse {
    status: number;
    data: {
        data?: {
            alternateLogin: boolean;
        };
        message?: string;
        status?: boolean;
    };
}

interface ChangePasswordResponse {
    data?: unknown;
    message: string;
    status: boolean;
}

interface ReportFilters {
    assignedTo: string;
    statusSelectedIds: string[];
    typeSelectedIds: string[];
    datePickerDateRange: CCDatePickerDateRange | undefined;
    searchReportText: string;
    markerId: string;
    folderId: string;
    initialSelectedItem: NavigationListItem | undefined;
}

interface StoredRunningJobs {
    page: MainTabs,
    tasks: RunningJobs[]
}

const EMPTY_REPORT_FILTERS: ReportFilters = {
    assignedTo: '',
    statusSelectedIds: [
        EnumerationHelper.getEnumKeyFromValue(
            ReportStatus,
            ReportStatus.Unresolved
        ),
        EnumerationHelper.getEnumKeyFromValue(
            ReportStatus,
            ReportStatus.Urgent
        ),
        EnumerationHelper.getEnumKeyFromValue(
            ReportStatus,
            ReportStatus.In_Progress
        ),
        EnumerationHelper.getEnumKeyFromValue(
            ReportStatus,
            ReportStatus.Scheduled
        ),
    ],
    typeSelectedIds: [],
    datePickerDateRange: undefined,
    searchReportText: '',
    markerId: '',
    folderId: '',
    initialSelectedItem: undefined,
} as ReportFilters;

export enum MainTabs {
    Reports = 1,
    Routes,
    Assignments,
    Schedule,
    Task,
    Analytics,
}

export interface ScheduleFilters {
    filterByDate: Date | string | undefined;
    statusSelectedIds: string[];
    folderToFilter: FolderFilterValues | undefined;
    propertyIdToFilter: string;
}

const EMPTY_SCHEDULE_FILTERS: ScheduleFilters = {
    filterByDate: undefined,
    statusSelectedIds: [],
    folderToFilter: undefined,
    propertyIdToFilter: ''
};

export interface storedCustomFilterValue {
    param: string;
    selectedValued: string | undefined;
}

export interface PlannedTaskFilters {
    hasUserDocFilters: boolean;
    datePickerDateRange: CCDatePickerDateRange | undefined;
    statusSelectedIds: string[];
    folderToFilter: FolderFilterValues | undefined;
    propertyIdToFilter: string;
    reviewStatusSelectedIds: string[];
    customSelectedFilters: storedCustomFilterValue[];
}

const EMPTY_PLANNED_TASK_FILTERS: PlannedTaskFilters = {
    hasUserDocFilters: false,
    datePickerDateRange: undefined,
    statusSelectedIds: [],
    folderToFilter: undefined,
    propertyIdToFilter: '',
    reviewStatusSelectedIds: [],
    customSelectedFilters: []
};

export class ManagerStore {
    //#region Observables
    @observable
    currentLoginWizardStep: LoginWizardSteps = LoginWizardSteps.Login;

    @observable
    isAlternateLoginRequired = false;

    @observable
    isLoginLoading = false;

    @observable
    isTeamLoading = false;

    @observable
    loginError = '';

    @observable
    userTeams: UserTeam[] = [] as UserTeam[];

    @observable
    currentAccount: Account | undefined = undefined as Account | undefined;

    @observable
    userModuleGroups: ReportModuleGroup[] = [] as ReportModuleGroup[];

    @observable
    isTeamReportsLoading = false;

    @observable
    teamReports: Report[] | undefined = undefined as Report[] | undefined;

    @observable
    teamReportsTotal = 0;

    @observable
    isTeamMarkersLoading = false;

    @observable
    teamMarkers: Marker[] = [] as Marker[];

    @observable
    teamMarkersTotal = 0;

    @observable
    teamFolders: Folder[] = [] as Folder[];

    @observable
    teamFoldersTotal = 0;

    @observable
    isTeamFoldersLoading = false;

    @observable
    isFolderMarkersLoading = false;

    @observable
    folderMarkers: Marker[] = [] as Marker[];

    @observable
    folderMarkersTotal = 0;

    @observable
    folderFloorplans: Floorplan[] = [] as Floorplan[];

    @observable
    folderFloorplansTotal = 0;

    @observable
    isFolderFloorplansLoading = false;

    @observable
    floorplanMarkers: Marker[] = [] as Marker[];

    @observable
    floorplanMarkersTotal = 0;

    @observable
    isFloorplanMarkersLoading = false;

    @observable
    reportModuleGroups: ReportModuleGroup[] = [] as ReportModuleGroup[];

    @observable
    isReportModuleGroupsLoading = false;

    @observable
    reportModules: ReportModule[] = [] as ReportModule[];

    @observable
    isReportModulesLoading = false;

    @observable
    isSubmittingReport = false;

    @observable
    currentReportDetail: ReportDetail | undefined = undefined;

    @observable
    isReportDetailLoading = false;

    @observable
    reportDetailComments: ReportComment[] | undefined = undefined;

    @observable
    isReportDetailCommentsLoading = false;

    @observable
    isReportDetailPostCommentLoading = false;

    @observable
    isReportDetailStatusChangeLoading = false;

    @observable
    isReportDetailAssignLoading = false;

    @observable
    isReportDetailDeleteLoading = false;

    @observable
    isReportChangeAgreeLoading = false;

    @observable
    vocFeedback: VocFeedback | undefined = undefined as VocFeedback | undefined;

    @observable
    isVocFeedbackLoading = false;

    @observable
    isAnalyticsTabLoading = false;

    @observable
    error = '';

    @observable
    errorAnalyticsTab = '';

    @observable
    isSchedulesTabLoading = false;

    @observable
    isSchedulesUploading = false;

    @observable
    errorUploadSchedules = '';

    @observable
    isTasksTabLoading = false;

    @observable
    errorTasksTab = '';

    @observable
    errorAnnotation = '';

    @observable
    isAnnotationLoading = false;

    @observable
    scheduleRoutes: ScheduleRoute[] = [] as ScheduleRoute[];

    @observable
    scheduleRoutesTotal = 0;

    @observable
    schedulesRoutesRows: GridRowsProp = [] as GridRowsProp;

    @observable
    isRoutesTabLoading = false;

    @observable
    teamUsers: SearchResultsUser[] = [] as SearchResultsUser[];

    @observable
    isTeamUsersLoading = false;

    @observable
    pcnRoutesTotal = 0;

    @observable
    isPcnRoutesLoading = false;

    @observable
    pcnRoutes: PCNList[] = [] as PCNList[];

    @observable
    isPcnMapLoading = false;

    @observable
    private pLoggedInUser: RegisteredUser | undefined = undefined as
        | RegisteredUser
        | undefined;

    @observable
    private pLoginAuthorization: LoginAuthorization | undefined = undefined as
        | LoginAuthorization
        | undefined;

    @observable
    private pUserData: User | undefined = undefined as User | undefined;

    @observable
    private pCurrentUserTeam: UserTeam | undefined = undefined as
        | UserTeam
        | undefined;

    @observable
    private pCurrentAnalyticsTab: AnalyticsTab | undefined = undefined as
        | AnalyticsTab
        | undefined;

    @observable
    private pCurrentSelectedTabIndex = -1;

    @observable
    private pCurrentRouteName = '';

    @observable
    private pSessionExpired = false;

    @observable
    private pReportFilters: ReportFilters | undefined = undefined as
        | ReportFilters
        | undefined;

    @observable
    private pQRMarkerId = '';

    @observable
    pIsReportDrawerOpen: boolean | undefined;

    @observable
    private pScheduleFilters: ScheduleFilters | undefined = undefined as
        | ScheduleFilters
        | undefined;

    @observable
    private pPlannedTasksFilters: PlannedTaskFilters | undefined = undefined as
        | PlannedTaskFilters
        | undefined;

    
    @observable
    private currentStoredJobsInternal: StoredRunningJobs[] | null = null;

    //#endregion

    //#region Computed
    @computed
    get loggedInUser(): RegisteredUser | undefined {
        if (!this.pLoggedInUser) {
            const registeredUserInCookie = this.loadRegisteredUser();
            if (registeredUserInCookie) {
                HttpConnectorAdapter.setRegisteredUser(registeredUserInCookie);
                HttpConnectorAdapter.onSSORefreshToken = this.onSSORefreshToken;
                this.pLoggedInUser = registeredUserInCookie;
            }
        }
        return this.pLoggedInUser;
    }

    @computed
    get loginAuthorization(): LoginAuthorization | undefined {
        if (!this.pLoginAuthorization) {
            const loginAuthorizationInCookie = this.loadLoginAuthorization();
            if (loginAuthorizationInCookie) {
                this.pLoginAuthorization = loginAuthorizationInCookie;
                HttpConnectorAdapter.setAuthorization(
                    loginAuthorizationInCookie
                );
            }
        }
        return this.pLoginAuthorization;
    }

    @computed
    get user(): User | undefined {
        return this.pUserData;
    }

    @computed
    get currentUserTeam(): UserTeam | undefined {
        if (!this.pCurrentUserTeam) {
            const currentUserTeamSerialized = getItemFromLocalStorage(
                COOKIE_NAMES.CURRENT_TEAM,
                true
            );
            let currentUserTeam;
            if (currentUserTeamSerialized) {
                currentUserTeam = UserTeam.createFromSerializedData(
                    currentUserTeamSerialized
                );
                if (currentUserTeam) {
                    this.updateCurrentUserTeam(currentUserTeam);
                }
            }
        }
        return this.pCurrentUserTeam;
    }

    @computed
    get currentAnalyticsTab(): AnalyticsTab | undefined {
        return this.pCurrentAnalyticsTab;
    }

    @computed
    get currentSelectedTabIndex(): number {
        return this.pCurrentSelectedTabIndex;
    }

    @computed
    get currentRouteName(): string {
        return this.pCurrentRouteName;
    }

    @computed
    get isSessionExpired(): boolean {
        return this.pSessionExpired;
    }

    @computed
    get reportFilters(): ReportFilters {
        let reportFilters = this.pReportFilters;
        if (reportFilters) {
            return reportFilters;
        }

        reportFilters = this.loadReportFilters();
        if (reportFilters) {
            this.pReportFilters = reportFilters;
            return reportFilters;
        }

        return EMPTY_REPORT_FILTERS;
    }

    @computed
    get qrMarkerId(): string {
        if (!this.pQRMarkerId) {
            const qrMarkerId = getItemFromLocalStorage(COOKIE_NAMES.QR_CODE);
            if (!qrMarkerId) {
                return '';
            }

            this.pQRMarkerId = qrMarkerId;
        }
        return this.pQRMarkerId;
    }

    @computed
    get isReportDrawerOpen(): boolean {
        if (this.pIsReportDrawerOpen == undefined) {
            const isReportDrawerOpenString = getItemFromLocalStorage(
                COOKIE_NAMES.REPORT_DRAWER_OPEN
            );
            this.pIsReportDrawerOpen =
                isReportDrawerOpenString?.toLocaleLowerCase() === 'true';
        }

        return this.pIsReportDrawerOpen;
    }

    @computed
    get scheduleFilters(): ScheduleFilters {
        let scheduleFilters = this.pScheduleFilters;
        if (scheduleFilters) {
            return scheduleFilters;
        }

        scheduleFilters = this.loadScheduleFilters();
        if (scheduleFilters) {
            this.pScheduleFilters = scheduleFilters;
            return scheduleFilters;
        }

        return EMPTY_SCHEDULE_FILTERS;
    }

    @computed
    get plannedTaskFilters(): PlannedTaskFilters {
        let plannedTaskFilters = this.pPlannedTasksFilters;
        if (plannedTaskFilters) {
            return plannedTaskFilters;
        }

        plannedTaskFilters = this.loadPlannedTaskFilters();
        if (plannedTaskFilters) {
            this.pPlannedTasksFilters = plannedTaskFilters;
            return plannedTaskFilters;
        }

        return EMPTY_PLANNED_TASK_FILTERS;
    }

    @computed
    get currentStoredJobs(): StoredRunningJobs[] {
        const storedData = getItemFromLocalStorage(COOKIE_NAMES.STORED_TASKS);
        
        if (!storedData) {
            return [];
        }

        this.currentStoredJobsInternal = JSON.parse(decryptText(storedData)) as StoredRunningJobs[];
        return this.currentStoredJobsInternal;
    }


    set currentStoredJobs(storedTasks: StoredRunningJobs[]) {
        const serializedData = JSON.stringify(storedTasks);
        setItemToLocalStorage(COOKIE_NAMES.STORED_TASKS, encryptText(serializedData));
        this.currentStoredJobsInternal = storedTasks;
    }

    //#endregion

    constructor(protected readonly rootStore: RootStoreBase) {
        // Since MobX version 6 is necessary use makeObservable to enabling decorators
        // For more info visit https://mobx.js.org/enabling-decorators.html
        makeObservable(this);
        if (!this.currentStoredJobs || this.currentStoredJobs.length <= 0) {
            // SET THE PAGES THAT HAVE RUNNING TASKS
            this.initTasksCookie();
        }
    }

    //#region initTasksCookie
    @action
    initTasksCookie = () => {
        this.currentStoredJobsInternal = [
            { page: MainTabs.Routes, tasks: [] },
        ];
        this.currentStoredJobs = this.currentStoredJobsInternal;
    };
    //#endregion

    //#region Actions

    @action
    setCurrentLoginWizardStep = (currentStep: LoginWizardSteps): void => {
        this.currentLoginWizardStep = currentStep;
    };

    @action
    setCurrentUserTeam = (currentUserTeam: UserTeam | undefined): void => {
        this.updateCurrentUserTeam(currentUserTeam);
    };

    @action
    clearLoginError = (): void => {
        this.loginError = '';
    };

    //#region checkEmail
    @action
    checkEmail = (email: string): void => {
        const {
            adapters: { loginAuthorizationAdapter },
        } = this.rootStore;
        loginAuthorizationAdapter
            .checkEmail(email)
            .then((authorization: LoginAuthorization) => {
                const { valid, authenticationType, redirectUrl, errorCode } =
                    authorization;

                if (!valid) {
                    this.isLoginLoading = false;
                    this.loginError = errorCode ? errorCode : '';
                    return;
                }

                if (authenticationType === AuthenticationType.SSO) {
                    if (redirectUrl) {
                        this.saveLoginAuthorization(authorization);
                        window.location.href = redirectUrl;
                        return;
                    } else {
                        this.isLoginLoading = false;
                        this.loginError = 'Missing redirect URL';
                        return;
                    }
                } else {
                    this.saveLoginAuthorization(undefined);
                    this.currentLoginWizardStep = LoginWizardSteps.Password;
                }

                this.isLoginLoading = false;
                this.loginError = '';
            })
            .catch((data) => {
                const message =
                    data && data.message
                        ? data.response &&
                          data.response.data &&
                          data.response.data.error_code
                            ? data.response.data.error_code
                            : data.message
                        : 'Network Error';
                this.isLoginLoading = false;
                this.loginError = message;
            });
        this.isLoginLoading = true;
    };
    //#endregion

    //#region login
    @action
    login = (email: string, password: string, captcha: string): void => {
        const {
            adapters: { loginAuthorizationAdapter },
        } = this.rootStore;

        this.loginError = '';
        this.error = '';

        loginAuthorizationAdapter
            .login(email, password, captcha)
            .then((registeredUser: RegisteredUser) => {
                this.isLoginLoading = false;
                const isLoggedIn = this.parseLoginResults(
                    registeredUser,
                    password
                );
                this.loginError = isLoggedIn ? '' : 'Invalid credentials';
            })
            .catch((data: { response: LoginResponse; message: string }) => {
                this.isLoginLoading = false;

                if (!data) {
                    return;
                }

                const {
                    response: { data: dataFromResponse, status },
                    message,
                } = data;

                const responseData = dataFromResponse
                    ? dataFromResponse.data
                    : undefined;
                const isAlternateLoginRequired = responseData
                    ? !!responseData.alternateLogin
                    : false;

                this.isAlternateLoginRequired = isAlternateLoginRequired;
                this.loginError =
                    status === 401
                        ? isAlternateLoginRequired
                            ? 'Please complete captcha'
                            : 'Invalid credentials'
                        : `${message}`;
            });

        this.isLoginLoading = true;
    };
    //#endregion

    //#region loginJwt
    @action
    loginJwt = (jwt: string | null): Promise<RegisteredUser> => {
        return new Promise<RegisteredUser>((resolve, reject) => {
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;

            if (!jwt) {
                return reject();
            }

            this.loginError = '';
            this.error = '';

            loginAuthorizationAdapter
                .loginJwt(jwt)
                .then((registeredUser: RegisteredUser) => {
                    this.isLoginLoading = false;
                    this.loginError = this.parseLoginResults(registeredUser)
                        ? ''
                        : 'Invalid credentials';
                    return resolve(registeredUser);
                })
                .catch(() => {
                    return reject();
                });
        });
    };
    //#endregion

    //#region loginSSO
    @action
    loginSSO = (
        code: string,
        state: string | undefined
    ): Promise<RegisteredUser> => {
        return new Promise<RegisteredUser>((resolve, reject) => {
            const authorization = this.loginAuthorization;
            if (!authorization) {
                return reject();
            }

            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;

            this.loginError = '';
            this.error = '';

            loginAuthorizationAdapter
                .checkSSOAuthorization(authorization, code, state)
                .then((registeredUser: RegisteredUser) => {
                    this.isLoginLoading = false;
                    this.pSessionExpired = false;
                    const isLoginParsed =
                        this.parseLoginResults(registeredUser);
                    if (isLoginParsed) {
                        this.loginError = '';
                        if (registeredUser.valid) {
                            const expiration = registeredUser.expiration;
                            if (expiration) {
                                HttpConnectorAdapter.setSSOTimeout(
                                    expiration.getTime()
                                );
                                HttpConnectorAdapter.onSSORefreshToken =
                                    this.onSSORefreshToken;
                                this.pSessionExpired =
                                    HttpConnectorAdapter.isSSOTimeoutExpired();
                            }
                        }
                        return resolve(registeredUser);
                    } else {
                        this.loginError = 'Invalid credentials';
                        return reject();
                    }
                })
                .catch((data: { errorMessage: string }) => {
                    this.isLoginLoading = false;

                    if (data) {
                        const { errorMessage } = data;

                        this.loginError = errorMessage
                            ? errorMessage
                            : 'Login Error';
                    }

                    this.saveLoginAuthorization(undefined);
                    this.clearRegisteredUser();

                    return reject();
                });

            this.isLoginLoading = true;
        });
    };
    //#endregion

    //#region loginMarkerProxy
    @action
    loginMarkerProxy = (
        markerId: string
    ): Promise<RegisteredUser> => {
        return new Promise<RegisteredUser>((resolve, reject) => {
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;

            this.loginError = '';
            this.error = '';

            loginAuthorizationAdapter
                .loginMarkerProxy(markerId)
                .then((registeredUser: RegisteredUser) => {
                    this.isLoginLoading = false;
                    this.pSessionExpired = false;
                    const isLoginParsed =
                        this.parseLoginResults(registeredUser);
                    if (isLoginParsed) {
                        this.loginError = '';
                        if (registeredUser.valid) {
                            const expiration = registeredUser.expiration;
                            if (expiration) {
                                HttpConnectorAdapter.setSSOTimeout(
                                    expiration.getTime()
                                );
                                HttpConnectorAdapter.onSSORefreshToken =
                                    this.onSSORefreshToken;
                                this.pSessionExpired =
                                    HttpConnectorAdapter.isSSOTimeoutExpired();
                            }
                        }
                        return resolve(registeredUser);
                    } else {
                        this.loginError = 'Invalid credentials';
                        return reject();
                    }
                })
                .catch((error) => {
                    this.isLoginLoading = false;
                    // Track message in case of error
                    // this is expected if ip is not authorized to login by proxy
                    const { response: { status }} = error;
                    if (status === 401) {
                        console.info('IP is not authorized to login by proxy');
                    } else {
                        console.error(`Unexpected error - ${error}`);
                    }

                    return reject();
                });

            this.isLoginLoading = true;
        });
    };
    //#endregion

    //#region logout
    @action logout = (): void => {
        const authorization = this.loadLoginAuthorization();
        let validationId;
        let isSSO = false;
        if (authorization) {
            validationId = authorization.validationId;
            isSSO = authorization.authenticationType === AuthenticationType.SSO;
            this.saveLoginAuthorization(undefined);
        }

        this.clearRegisteredUser();

        this.loginError = '';
        this.error = '';
        this.pUserData = undefined;
        this.userTeams = [];
        this.userModuleGroups = [] as ReportModuleGroup[];
        this.pCurrentSelectedTabIndex = -1;
        this.pCurrentRouteName = '';
        this.pSessionExpired = false;
        this.setCurrentLoginWizardStep(LoginWizardSteps.Login);

        this.setCurrentUserTeam(undefined);
        this.setReportFilters(undefined);
        this.clearScheduleFilters();
        this.clearPlannedTaskFilters();
        // CLEAR RUNNING JOBS COOKIE
        this.initTasksCookie();

        this.ssoLogout(validationId);
        if (!isSSO) {
            this.rootStore.routerStore.goTo(ROUTE_NAMES.LOGIN, {});
        }
    };
    //#endregion

    //#region resetPassword
    @action resetPassword = (email: string): void => {
        const {
            adapters: { loginAuthorizationAdapter },
        } = this.rootStore;

        this.loginError = '';
        this.error = '';

        loginAuthorizationAdapter
            .resetPassword(email)
            .then(() => {
                this.isLoginLoading = false;
                this.loginError = '';
                this.currentLoginWizardStep =
                    LoginWizardSteps.PasswordResetSuccess;
            })
            .catch((data: { message: string }) => {
                this.isLoginLoading = false;
                this.loginError = data.message;
            });

        this.isLoginLoading = true;
    };
    //#endregion

    //#region changePassword
    @action changePassword = (oldPassword: string, newPassword: string): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;

            this.loginError = '';
            this.error = '';

            loginAuthorizationAdapter
                .changePassword(oldPassword, newPassword)
                .then((response) => {
                    const { message } = response as ChangePasswordResponse;
                    this.isLoginLoading = false;
                    this.loginError = '';
                    return resolve(message);
                })
                .catch((data: { message: string }) => {
                    this.isLoginLoading = false;
                    this.loginError = `Unknown error while changing password - ${data.message}`;
                    return reject(this.loginError);
                });

            this.isLoginLoading = true;
        });
    };
    //#endregion

    //#region createNewAccount
    @action
    createNewAccount = (
        username: string,
        email: string,
        password: string,
        accessCode: string
    ): Promise<RegisteredUser> => {
        return new Promise<RegisteredUser>((resolve, reject) => {
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;

            loginAuthorizationAdapter
                .createNewAccount(username, email, password, accessCode)
                .then((registeredUser: RegisteredUser) => {
                    this.isLoginLoading = false;
                    const successfullyParsed = this.parseLoginResults(
                        registeredUser,
                        password
                    );
                    this.loginError = successfullyParsed ? '' : 'Unknown error';
                    return successfullyParsed
                        ? resolve(registeredUser)
                        : reject(this.loginError);
                })
                .catch(
                    (data: {
                        response: { status: number; data: { message: string } };
                        message: string;
                    }) => {
                        const {
                            response: {
                                status,
                                data: { message: errorMessage },
                            },
                            message,
                        } = data;

                        this.isLoginLoading = false;
                        this.loginError =
                            status === 401
                                ? 'Invalid access code'
                                : errorMessage
                                    ? errorMessage
                                    : `${message}`;
                        return reject(this.loginError);
                    }
                );
        });
    };
    //#endregion

    //#region validateNewAccount
    @action
    validateNewAccount = (userId: string, code: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;
            loginAuthorizationAdapter
                .validateNewAccount(userId, code)
                .then((data: unknown) => {
                    const { message: errorMessage, status } = data as {
                        message: string;
                        status: boolean;
                    };
                    if (status === true) {
                        if (
                            this.pLoggedInUser &&
                            this.pLoggedInUser.isVerificationNeeded
                        ) {
                            const encryptedData = getItemFromLocalStorage(
                                COOKIE_NAMES.USER_VERIFICATION_PASSWORD
                            );
                            deleteItemFromLocalStorage(
                                COOKIE_NAMES.USER_VERIFICATION_PASSWORD
                            );
                            if (encryptedData) {
                                const userPassword = decryptText(encryptedData);
                                const userEmail = this.pLoggedInUser.email;
                                this.login(userEmail, userPassword, '');
                            }
                        }
                        return resolve();
                    }
                    this.loginError = errorMessage;
                    return reject(errorMessage);
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((data: any) => {
                    if (!data) {
                        this.loginError = 'Server Error';
                        return reject();
                    }

                    const {
                        status,
                        data: { message: errorMessage },
                        statusText,
                    } = data;

                    this.loginError =
                        status === 401
                            ? 'Invalid access code'
                            : errorMessage
                                ? errorMessage
                                : `${statusText}`;
                    return reject(this.loginError);
                });
        });
    };
    //#endregion

    //#region clearCurrentAnalyticsTab
    @action
    clearCurrentAnalyticsTab = (): void => {
        this.pCurrentAnalyticsTab = undefined;
    };
    //#endregion

    //#region setCurrentSelectedTabIndex
    @action
    setCurrentSelectedTabIndex = (newTabIndex: number): void => {
        const finalValue = newTabIndex >= 0 ? newTabIndex : -1;
        this.pCurrentSelectedTabIndex = finalValue;
    };
    //#endregion

    //#region setCurrentRoute
    @action
    setCurrentRouteName = (newRouteName: string): void => {
        this.pCurrentRouteName = newRouteName;
    };
    //#endregion

    //#region getUserTeams
    @action
    getUserTeams = (): Promise<UserTeam[]> => {
        return new Promise<UserTeam[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            reportAdapter
                .getUserTeams(registeredUser)
                .then((teams: UserTeam[]) => {
                    this.userTeams = teams;
                    if (!this.currentUserTeam) {
                        this.setCurrentUserTeam(
                            teams.length > 0 ? teams[0] : undefined
                        );
                    }
                    resolve(teams);
                })
                .catch(
                    (errorData?: {
                        error?: number;
                        response?: { status: number };
                    }) => {
                        let errorMessage = 'Unable to retrieve user teams';
                        if (errorData) {
                            const { error: responseError, response } =
                                errorData;
                            const responseStatus = response
                                ? response.status
                                : -1;
                            if (
                                responseStatus === 401 ||
                                (registeredUser.isSSO && responseError === -1)
                            ) {
                                this.pSessionExpired = true;
                            } else if (
                                responseStatus >= 500 &&
                                responseStatus < 511
                            ) {
                                errorMessage = `${errorMessage}. Server Error (${responseStatus})`;
                            }
                        }
                        this.userTeams = [];
                        this.setCurrentUserTeam(undefined);
                        this.error = errorMessage;
                        return reject();
                    }
                );
        });
    };
    //#endregion

    //#region getUser
    @action
    getUser = (userId: string): Promise<User | undefined> => {
        return new Promise<User | undefined>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }
            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;
            loginAuthorizationAdapter
                .getUser(registeredUser, userId)
                .then((user: User) => {
                    this.pUserData = user;
                    resolve(user);
                })
                .catch((errorData) => {
                    let errorMessage = 'Unable to retrieve user data';
                    let responseStatus = -1;
                    if (errorData) {
                        const { response } = errorData;
                        responseStatus = response ? response.status : -1;
                        if (responseStatus > 0) {
                            errorMessage = `${errorMessage}. Server Error (${responseStatus})`;
                        }
                    }
                    this.pUserData = undefined;
                    this.error = errorMessage;
                    return reject({ errorStatus: responseStatus, errorData });
                });
        });
    };
    //#endregion

    //#region getUser
    @action
    getUserPermissions = (folderId: string): Promise<UserPermissions> => {
        return new Promise<UserPermissions>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }

            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;
            loginAuthorizationAdapter
                .getUserPermissions(registeredUser, folderId)
                .then((response) => {
                    resolve(response);
                })
                .catch((errorResponse) => {
                    const errorMessage = `Unable to retrieve user permissions data - ${errorResponse}`;
                    this.error = errorMessage;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region getTeam
    @action
    getTeam = (teamId: string, userId = ''): Promise<Account | undefined> => {
        return new Promise<Account | undefined>((resolve, reject) => {
            let userIdFinal: string;
            if (userId) {
                userIdFinal = userId;
            } else {
                const registeredUser = this.loggedInUser;
                if (!registeredUser) {
                    this.error = SESSION_EXPIRED_ERROR_TEXT;
                    return reject(SESSION_EXPIRED_ERROR_TEXT);
                } else {
                    userIdFinal = registeredUser.userId;
                }
            }

            this.isTeamLoading = true;

            const {
                adapters: { loginAuthorizationAdapter },
            } = this.rootStore;
            loginAuthorizationAdapter
                .getAccount(teamId, userIdFinal)
                .then((account: Account) => {
                    this.currentAccount = account;
                    this.isTeamLoading = false;
                    resolve(account);
                })
                .catch(() => {
                    const errorMessage = 'Unable to retrieve account data.';
                    this.currentAccount = undefined;
                    this.isTeamLoading = false;
                    this.error = errorMessage;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region getTeamModules
    @action
    getTeamModules = (teamId: string): Promise<ReportModuleGroup[]> => {
        return new Promise<ReportModuleGroup[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            reportAdapter
                .getTeamModuleGroups(registeredUser, teamId, 'atomic')
                .then((modules: ReportModuleGroup[]) => {
                    this.userModuleGroups = modules;
                    resolve(modules);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to retrieve user modules.';
                    this.userModuleGroups = [] as ReportModuleGroup[];
                    this.error = errorMessage;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region resetTeamTotals
    @action
    resetTeamReportsTotal = (): void => {
        this.teamReportsTotal = 0;
    };
    //#endregion

    //#region report filters actions

    //#region updateReportFilter
    @action
    updateReportFilter = (
        updatedReportFilters: Partial<ReportFilters>
    ): void => {
        const pReportFilters = this.pReportFilters
            ? this.pReportFilters
            : EMPTY_REPORT_FILTERS;

        this.setReportFilters({ ...pReportFilters, ...updatedReportFilters });
    };
    //#endregion

    //#region setReportFilters
    @action
    setReportFilters = (reportFilters: ReportFilters | undefined): void => {
        if (!reportFilters) {
            this.clearReportFilters();
            return;
        }

        this.pReportFilters = reportFilters;
        this.saveReportFilters(reportFilters);
    };
    //#endregion

    //#endregion

    //#region getTeamReports
    @action
    getTeamReports = (
        limit: number,
        statuses: string[],
        types: string[] | undefined,
        assignedTo: string | undefined,
        search = '',
        offset = 0,
        fromDate?: Date,
        toDate?: Date,
        markerId?: string,
        folderId?: string
    ): Promise<Report[]> => {
        return new Promise<Report[]>((resolve, reject) => {
            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const { currentUserTeam, loggedInUser: registeredUser } = this;
            if (!registeredUser || !currentUserTeam) {
                const error = 'Unregistered User';
                this.error = error;
                return reject(error);
            }

            this.error = '';
            this.isTeamReportsLoading = true;

            reportAdapter
                .getTeamReports(
                    registeredUser,
                    currentUserTeam.teamId,
                    limit,
                    statuses,
                    types,
                    assignedTo,
                    search,
                    offset,
                    fromDate,
                    toDate,
                    markerId,
                    folderId
                )
                .then((results: SearchResults<Report>) => {
                    const {
                        rows: report,
                        meta: { totalCount },
                    } = results;

                    this.teamReports = report;
                    this.teamReportsTotal = totalCount;
                    this.isTeamReportsLoading = false;
                    return resolve(report);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const error = 'Unable to retrieve team report';
                    this.teamReports = undefined;
                    this.error = error;
                    this.isTeamReportsLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getTeamReportsForCsvExport
    @action
    getTeamReportsForCsvExport = (
        limit: number,
        statuses: string[],
        types: string[] | undefined,
        assignedTo: string | undefined,
        search = '',
        offset = 0,
        fromDate?: Date,
        toDate?: Date,
        teamId?: string,
        markerId?: string,
        folderId?: string
    ): Promise<SearchResults<Report>> => {
        return new Promise<SearchResults<Report>>((resolve, reject) => {
            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const { currentUserTeam, loggedInUser: registeredUser } = this;
            const error = 'Unregistered User';

            if (!registeredUser || !currentUserTeam || !teamId) {
                return reject(error);
            }

            return reportAdapter
                .getTeamReportsForCsvExport(
                    registeredUser,
                    teamId ? teamId : currentUserTeam.teamId,
                    limit,
                    statuses,
                    types,
                    assignedTo,
                    search,
                    offset,
                    fromDate,
                    toDate,
                    markerId,
                    folderId
                )
                .then((result: SearchResults<Report>) => {
                    return resolve(result);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getReportByUser
    @action
    getReportsByUser = (limit = 10, offset = 0): Promise<Report[]> => {
        return new Promise<Report[]>((resolve, reject) => {
            const { loggedInUser: registeredUser } = this;

            if (!registeredUser) {
                const error = 'Unregistered User';
                this.error = error;
                return reject(error);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isTeamReportsLoading = true;

            reportAdapter
                .getReportsByUser(registeredUser, limit, offset)
                .then((results: SearchResults<Report>) => {
                    const {
                        rows: report,
                        meta: { totalCount },
                    } = results;

                    this.teamReports = report;
                    this.teamReportsTotal = totalCount;
                    this.isTeamReportsLoading = false;
                    return resolve(report);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const error = 'Unable to retrieve reports.';
                    this.teamReports = undefined;
                    this.error = error;
                    this.isTeamReportsLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region clearTeamMarkersSearch
    @action
    clearTeamMarkersSearch = (): void => {
        this.teamMarkers = [];
        this.teamMarkersTotal = 0;
        this.isTeamMarkersLoading = false;
    };
    //#endregion

    //#region getTeamMarkersSearch
    @action
    getTeamMarkersSearch = (
        termsString: string,
        limit = 10, // Server default when limit isn't specified.
        offset?: number
    ): Promise<SearchResults<Marker>> => {
        return new Promise<SearchResults<Marker>>((resolve, reject) => {
            if (!this.currentUserTeam || !termsString) {
                return reject('Missing user team or search terms.');
            }

            const { teamId } = this.currentUserTeam;
            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isTeamMarkersLoading = true;
            reportAdapter
                .getTeamMarkersSearch(
                    registeredUser,
                    teamId.trim(),
                    termsString.trim(),
                    limit,
                    offset
                )
                .then((searchResults: SearchResults<Marker>) => {
                    const {
                        rows: markers,
                        meta: { totalCount },
                    } = searchResults;

                    this.teamMarkers = markers;
                    this.teamMarkersTotal = totalCount;
                    this.isTeamMarkersLoading = false;
                    return resolve(searchResults);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve team markers';
                    this.error = error;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getTeamMarkersInFolder
    @action
    getTeamMarkersInFolder = (
        teamId: string,
        folderId: string,
        limit = 10,
        offset = 0
    ): Promise<SearchResults<Marker>> => {
        return new Promise<SearchResults<Marker>>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }
            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .getTeamMarkersInFolder(
                    registeredUser,
                    teamId,
                    folderId,
                    limit,
                    offset
                )
                .then((results) => {
                    resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ErrorMessage =
                        'Unable to get marker for the selected folder.';
                    this.error = ErrorMessage;
                    // this.teamFolders = [] as Folder[];
                    // this.teamFoldersTotal = 0;
                    // this.isTeamFoldersLoading = false;
                    reject(ErrorMessage);
                });
        });
    };
    //#endregion

    //#region getAccountFolderTree
    @action
    getAccountFolderTree = (
        permissions: string
    ): Promise<RenderTree> => {
        return new Promise<RenderTree>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }
            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .getAccountFolderTree(
                    registeredUser,
                    permissions
                )
                .then((results) => {
                    resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ErrorMessage =
                        'Unable to get account folders tree.';
                    this.error = ErrorMessage;
                    reject(ErrorMessage);
                });
        });
    };
    //#endregion

    //#region getFolderMarkers
    private sortMarkerFloorplanNames = memoize((markers: Marker[]): Marker[] => {
        // WE SORT BY FLOORPLAN THEN BY MARKER NAME
        // THIS SORT CURRENTLY IGNORES ROUTE ORDER
        return markers.sort((a,b) => {
            if (a.floorplanName !== b.floorplanName) {
                return a.floorplanName.localeCompare(b.floorplanName);
            } else {
                return a.name.localeCompare(b.name);
            }
        });
    });

    @action
    getFolderMarkers = (
        folderId: string
    ): Promise<SearchResults<Marker>> => {
        return new Promise<SearchResults<Marker>>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isFolderMarkersLoading = true;
            reportAdapter
                .getFolderMarkers(registeredUser, folderId)
                .then((searchResults: SearchResults<Marker>) => {
                    const {
                        rows: markers,
                        meta: { totalCount },
                    } = searchResults;

                    this.folderMarkers = this.sortMarkerFloorplanNames(markers);
                    this.folderMarkersTotal = totalCount;
                    this.isFolderMarkersLoading = false;
                    return resolve(searchResults);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve folder markers';
                    this.error = error;
                    this.isFolderMarkersLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getTeamUsers
    @action
    getTeamUsers = (
        teamId: string,
        email?: string
    ): Promise<SearchResultsUser[]> => {
        return new Promise<SearchResultsUser[]>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isTeamUsersLoading = true;
            reportAdapter
                .getTeamUsers(registeredUser, teamId, email)
                .then((users: SearchResultsUser[]) => {
                    this.isTeamUsersLoading = false;
                    this.teamUsers = users;
                    return resolve(users);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve team users';
                    this.error = error;
                    this.isTeamUsersLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getModulesByMarker
    @action
    getModulesByMarker = (markerId: string): Promise<ReportModuleGroup[]> => {
        return new Promise<ReportModuleGroup[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .getModulesByMarker(registeredUser, markerId)
                .then((modules: ReportModuleGroup[]) => {
                    this.reportModuleGroups = modules;
                    this.isReportModuleGroupsLoading = false;
                    resolve(modules);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }

                    this.error = 'Unable to load team modules';
                    this.reportModuleGroups = [] as ReportModuleGroup[];
                    this.isReportModuleGroupsLoading = false;
                    reject();
                });

            this.isReportModuleGroupsLoading = true;
        });
    };
    //#endregion

    //#region getMarkerById
    @action
    getMarkerById = (markerId: string): Promise<Marker | undefined> => {
        return new Promise<Marker | undefined>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .getMarkerById(registeredUser, markerId)
                .then((marker: Marker | undefined) => {
                    resolve(marker);
                })
                .catch(
                    (errorData?: {
                        error?: number;
                        response?: { status: number; data?: unknown };
                    }) => {
                        let returnResponse = {};
                        if (errorData) {
                            const { error: responseError, response } =
                                errorData;

                            const status = response?.status;
                            if (
                                status === 401 ||
                                (registeredUser.isSSO && responseError === -1)
                            ) {
                                this.pSessionExpired = true;
                            }
                            returnResponse = {
                                ...returnResponse,
                                ...{ status, data: response?.data },
                            };
                        }

                        const error = 'Unable to load marker';
                        this.error = error;
                        returnResponse = { ...returnResponse, ...{ error } };
                        reject(returnResponse);
                    }
                );
        });
    };
    //#endregion

    //#region getModuleInfo
    @action
    getModuleInfo = (
        markerId: string,
        moduleGroupId: string
    ): Promise<ReportModule[]> => {
        return new Promise<ReportModule[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .getModuleInfo(registeredUser, markerId, moduleGroupId)
                .then((modules: ReportModule[]) => {
                    this.reportModules = modules;
                    this.isReportModulesLoading = false;
                    resolve(modules);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error = 'Unable to load report modules';
                    this.reportModules = [] as ReportModule[];
                    this.isReportModulesLoading = false;
                    reject();
                });

            this.isReportModulesLoading = true;
        });
    };
    //#endregion

    //#region submitReport
    @action
    submitReport = (
        markerId: string,
        moduleId: string,
        moduleGroup: string,
        widgetValues: WidgetDataValues,
        status: ReportStatus,
        summary: string
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isSubmittingReport = true;

            reportAdapter
                .submitReport(
                    registeredUser,
                    markerId,
                    moduleId,
                    moduleGroup,
                    widgetValues,
                    status,
                    summary
                )
                .then(() => {
                    this.isSubmittingReport = false;
                    resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.isSubmittingReport = false;
                    const errorMessage = 'Unable to submit the report.';
                    this.error = errorMessage;
                    reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region invalidateCurrentReportDetail
    @action
    invalidateCurrentReportDetail = (): void => {
        this.currentReportDetail = undefined as ReportDetail | undefined;
    };
    //#endregion

    //#region getReportDetail
    @action
    getReportDetail = (
        reportId: string
    ): Promise<ReportResults<ReportDetail>> => {
        return new Promise<ReportResults<ReportDetail>>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .getReportDetail(registeredUser, reportId)
                .then((reportResults) => {
                    const { results: reportDetail, errors } = reportResults;
                    this.currentReportDetail = reportDetail;
                    this.isReportDetailLoading = false;
                    const errorsLength = errors.length;
                    if (errorsLength > 0) {
                        this.error = `Report details loaded with the following error${
                            errorsLength > 1 ? 's' : ''
                        }: ${errors.join('; ')}`;
                    } else {
                        this.error = '';
                    }
                    resolve(reportResults);
                })
                .catch((errorData) => {
                    let ERROR_MESSAGE = 'Unable to retrieve report details.';
                    if (typeof errorData === 'object') {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    } else if (typeof errorData === 'string') {
                        ERROR_MESSAGE = `${ERROR_MESSAGE} - ${errorData}`;
                    }

                    this.currentReportDetail = undefined;
                    this.error = ERROR_MESSAGE;
                    this.isReportDetailLoading = false;
                    reject(ERROR_MESSAGE);
                });

            this.isReportDetailLoading = true;
        });
    };
    //#endregion

    //#region getReportComments
    @action
    getReportComments = (
        reportDetail: ReportDetail,
        limit: number,
        offset = 0
    ): Promise<ReportResults<ReportComment[]>> => {
        return new Promise<ReportResults<ReportComment[]>>(
            (resolve, reject) => {
                const registeredUser = this.loggedInUser;
                if (!registeredUser) {
                    this.error = SESSION_EXPIRED_ERROR_TEXT;
                    reject(SESSION_EXPIRED_ERROR_TEXT);
                    return;
                }

                const {
                    adapters: { reportAdapter },
                } = this.rootStore;

                reportAdapter
                    .getReportComments(
                        registeredUser,
                        reportDetail,
                        limit,
                        offset
                    )
                    .then((reportResults) => {
                        const { errors, results: reportComments } =
                            reportResults;
                        this.reportDetailComments = reportComments;
                        this.isReportDetailCommentsLoading = false;
                        const errorsLength = errors.length;
                        if (errorsLength > 0) {
                            this.error = `Report comments loaded with the following error${
                                errorsLength > 1 ? 's' : ''
                            }: ${errors.join('; ')}`;
                        } else {
                            this.error = '';
                        }
                        resolve(reportResults);
                    })
                    .catch((errorData) => {
                        if (errorData) {
                            const { error: responseError, response } =
                                errorData;

                            if (
                                response?.status === 401 ||
                                (registeredUser.isSSO && responseError === -1)
                            ) {
                                this.pSessionExpired = true;
                            }
                        }
                        const ERROR_MESSAGE =
                            'Unable to retrieve report comments.';
                        this.reportDetailComments = undefined;
                        this.error = ERROR_MESSAGE;
                        this.isReportDetailCommentsLoading = false;
                        reject(ERROR_MESSAGE);
                    });

                this.isReportDetailCommentsLoading = true;
            }
        );
    };
    //#endregion

    //#region postReportComment
    @action
    postReportComment = (
        reportId: string,
        comment: string
    ): Promise<ReportComment> => {
        return new Promise<ReportComment>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser || !comment) {
                reject();
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .postReportComment(registeredUser, reportId, comment)
                .then((comments: ReportComment) => {
                    this.isReportDetailPostCommentLoading = false;
                    resolve(comments);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ERROR_MESSAGE = 'Unable to submit the report comment';
                    this.error = ERROR_MESSAGE;
                    this.isReportDetailPostCommentLoading = false;
                    reject(ERROR_MESSAGE);
                });

            this.isReportDetailPostCommentLoading = true;
        });
    };
    //#endregion

    //#region changeReportCategory
    @action
    changeReportCategory = (
        teamId: string,
        reportId: string,
        moduleGroupId: string
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .changeReportCategory(
                    registeredUser,
                    teamId,
                    reportId,
                    moduleGroupId
                )
                .then(() => {
                    resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }

                    const errorMessage =
                        'Unable to update the category of the current report';
                    this.error = errorMessage;
                    reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region checkChangeCategoryByTeam
    @action
    checkChangeCategoryByTeam = (teamId: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';

            reportAdapter
                .checkChangeCategoryByTeam(registeredUser, teamId)
                .then((response) => {
                    resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }

                    const errorMessage =
                        'Cannot get team permissions to update category';
                    this.error = errorMessage;
                    reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region changeReportStatus
    @action
    changeReportStatus = (reportId: string, status: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .changeReportStatus(registeredUser, reportId, status)
                .then(() => {
                    this.isReportDetailStatusChangeLoading = false;
                    resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ERROR_MESSAGE =
                        'Unable to change the status of the current report';
                    this.error = ERROR_MESSAGE;
                    this.isReportDetailStatusChangeLoading = false;
                    reject(ERROR_MESSAGE);
                });

            this.isReportDetailStatusChangeLoading = true;
        });
    };
    //#endregion

    //#region assignReport
    @action
    assignReport = (reportId: string, email: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .assignReport(registeredUser, reportId, email)
                .then(() => {
                    this.isReportDetailAssignLoading = false;
                    resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ERROR_MESSAGE =
                        'Unable to change the assignee of the current report';
                    this.error = ERROR_MESSAGE;
                    this.isReportDetailAssignLoading = false;
                    reject(ERROR_MESSAGE);
                });

            this.isReportDetailAssignLoading = true;
        });
    };
    //#endregion

    //#region deleteReport
    @action
    deleteReport = (reportId: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .deleteReport(registeredUser, reportId)
                .then(() => {
                    this.isReportDetailDeleteLoading = false;
                    resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ERROR_MESSAGE = 'Unable to delete the current report';
                    this.error = ERROR_MESSAGE;
                    this.isReportDetailDeleteLoading = false;
                    reject(ERROR_MESSAGE);
                });

            this.isReportDetailDeleteLoading = true;
        });
    };
    //#endregion

    //#region getFolders
    @action
    getTeamFolders = (
        teamId: string,
        folderId: string,
        limit = 10,
        offset = 0,
        permissions?: string
    ): Promise<SearchResults<Folder>> => {
        return new Promise<SearchResults<Folder>>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isTeamFoldersLoading = true;

            reportAdapter
                .getTeamFolders(registeredUser, teamId, folderId, limit, offset, permissions)
                .then((results) => {
                    const {
                        rows: folders,
                        meta: { totalCount },
                    } = results;
                    this.teamFolders = folders;
                    this.teamFoldersTotal = totalCount;
                    this.isTeamFoldersLoading = false;
                    return resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error = 'Unable to get folders for the selected team.';
                    this.teamFolders = [] as Folder[];
                    this.teamFoldersTotal = 0;
                    this.isTeamFoldersLoading = false;
                    return reject(this.error);
                });
        });
    };
    //#endregion

    //#region getFolderFloorplans
    @action
    getFolderFloorplans = (
        teamId: string,
        folderId: string,
        limit = 10,
        offset = 0
    ): Promise<SearchResults<Floorplan>> => {
        return new Promise<SearchResults<Floorplan>>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isFolderFloorplansLoading = true;

            reportAdapter
                .getFolderFloorplan(
                    registeredUser,
                    teamId,
                    folderId,
                    limit,
                    offset
                )
                .then((results) => {
                    const {
                        rows: floorplans,
                        meta: { totalCount },
                    } = results;
                    this.folderFloorplans = floorplans;
                    this.folderFloorplansTotal = totalCount;
                    this.isFolderFloorplansLoading = false;
                    return resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error =
                        'Unable to get floorplans for the selected folder.';
                    this.folderFloorplans = [] as Floorplan[];
                    this.folderFloorplansTotal = 0;
                    this.isFolderFloorplansLoading = false;
                    return reject(this.error);
                });
        });
    };
    //#endregion

    //#region getFloorplanMarkers
    @action
    getFloorplanMarkers = (
        floorplanId: string,
        limit = 10,
        offset = 0
    ): Promise<SearchResults<Marker>> => {
        return new Promise<SearchResults<Marker>>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const { teamId } = this.currentUserTeam;
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isFloorplanMarkersLoading = true;

            reportAdapter
                .getFloorplanMarker(
                    registeredUser,
                    teamId,
                    floorplanId,
                    limit,
                    offset
                )
                .then((results) => {
                    const {
                        rows: markers,
                        meta: { totalCount },
                    } = results;
                    this.floorplanMarkers = markers;
                    this.floorplanMarkersTotal = totalCount;
                    this.isFloorplanMarkersLoading = false;
                    return resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error =
                        'Unable to get markers for the selected floorplan.';
                    this.floorplanMarkers = [] as Marker[];
                    this.floorplanMarkersTotal = 0;
                    this.isFloorplanMarkersLoading = false;
                    return reject(this.error);
                });
        });
    };
    //#endregion

    //#region getFloorplanMarkersSearch
    @action
    getFloorplanMarkersSearch = (
        floorplanId: string,
        termsString: string,
        limit = 10, // Server default when limit isn't specified.
        offset?: number
    ): Promise<SearchResults<Marker>> => {
        return new Promise<SearchResults<Marker>>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const { teamId } = this.currentUserTeam;
            const {
                adapters: { reportAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isFloorplanMarkersLoading = true;
            reportAdapter
                .getFloorplanMarkersSearch(
                    registeredUser,
                    floorplanId,
                    teamId.trim(),
                    termsString.trim(),
                    limit,
                    offset
                )
                .then((searchResults: SearchResults<Marker>) => {
                    const {
                        rows: markers,
                        meta: { totalCount },
                    } = searchResults;

                    this.floorplanMarkers = markers;
                    this.floorplanMarkersTotal = totalCount;
                    this.isFloorplanMarkersLoading = false;
                    return resolve(searchResults);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve team markers';
                    this.error = error;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region changeAgreeInReport
    @action
    changeAgreeInReport = (
        reportId: string,
        agree: boolean
    ): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                reject(SESSION_EXPIRED_ERROR_TEXT);
                return;
            }
            if (!reportId) {
                const ERROR_MESSAGE = 'reportId cannot be blank';
                this.error = ERROR_MESSAGE;
                reject(ERROR_MESSAGE);
                return;
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            reportAdapter
                .changeAgreeInReport(registeredUser, reportId, agree)
                .then((agreeValue: boolean) => {
                    this.isReportChangeAgreeLoading = false;
                    resolve(agreeValue);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const ERROR_MESSAGE = `Unable to change the agree value on the report with id: ${reportId}`;
                    this.error = ERROR_MESSAGE;
                    this.isReportChangeAgreeLoading = false;
                    reject(ERROR_MESSAGE);
                });
            this.isReportChangeAgreeLoading = true;
        });
    };
    //#endregion

    //#region getNewFeedbackForUser
    @action
    getNewFeedbackForUser = (
        userId: string,
        limit = 10,
        offset = 0
    ): Promise<VocFeedback> => {
        return new Promise<VocFeedback>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isVocFeedbackLoading = true;
            reportAdapter
                .getNewFeedbackForUser(registeredUser, userId, limit, offset)
                .then((results: VocFeedback) => {
                    this.vocFeedback = results;
                    this.isVocFeedbackLoading = false;
                    return resolve(results);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error = 'Unable to get user feedback data.';
                    this.vocFeedback = undefined as VocFeedback | undefined;
                    this.isVocFeedbackLoading = false;
                    return reject(this.error);
                });
        });
    };
    //#endregion

    //#region submitVocFeedback
    @action
    submitVocFeedback = (
        widgetData: submitVocWidgetData[],
        feedbackModuleId: string,
        reportId: string
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { reportAdapter },
            } = this.rootStore;

            this.error = '';
            this.isVocFeedbackLoading = true;
            reportAdapter
                .submitVocFeedback(
                    registeredUser,
                    widgetData,
                    feedbackModuleId,
                    reportId
                )
                .then(() => {
                    this.isVocFeedbackLoading = false;
                    return resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.error = 'Unable to submit user feedback.';
                    this.isVocFeedbackLoading = false;
                    return reject(this.error);
                });
        });
    };
    //#endregion

    //#region updateAnalyticsTab
    @action
    updateAnalyticsTab = (tabId: string): Promise<AnalyticsTab> => {
        return new Promise<AnalyticsTab>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { analyticsAdapter },
            } = this.rootStore;

            this.errorAnalyticsTab = '';
            analyticsAdapter
                .getAnalyticsTab(tabId)
                .then((tab: AnalyticsTab) => {
                    this.pCurrentAnalyticsTab = tab;
                    this.isAnalyticsTabLoading = false;
                    return resolve(tab);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to load the current tab';
                    this.errorAnalyticsTab = errorMessage;
                    this.pCurrentAnalyticsTab = undefined as
                        | AnalyticsTab
                        | undefined;
                    this.isAnalyticsTabLoading = false;
                    return reject(errorMessage);
                });
            this.isAnalyticsTabLoading = true;
        });
    };

    @action
    setQRMarkerId = (markerId: string): void => {
        if (!markerId) {
            deleteItemFromLocalStorage(COOKIE_NAMES.QR_CODE);
            this.pQRMarkerId = '';
            return;
        }

        setItemToLocalStorage(COOKIE_NAMES.QR_CODE, markerId);
        this.pQRMarkerId = markerId;
    };

    @action
    setIsReportDrawerOpen = (isReportDrawerOpen: boolean): void => {
        setItemToLocalStorage(
            COOKIE_NAMES.REPORT_DRAWER_OPEN,
            isReportDrawerOpen ? 'true' : 'false'
        );
        this.pIsReportDrawerOpen = isReportDrawerOpen;
    };
    //#endregion

    //#region getSchedulesTab
    @action
    getSchedules = (limit = 10, offset = 0, filters?: ScheduleFilterParameters): Promise<Schedules> => {
        return new Promise<Schedules>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .getSchedules(limit, offset, filters)
                .then((tab: SchedulesTab) => {
                    this.isSchedulesTabLoading = false;
                    const { schedules } = tab;
                    return resolve(schedules);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to load the Schedules tab';
                    this.isSchedulesTabLoading = false;
                    return reject(errorMessage);
                });
            this.isSchedulesTabLoading = true;
        });
    };
    //#endregion

    //#region submitSchedules
    @action
    submitSchedules = (accountId: string, file: File, fileUploadDetails?: UploadScheduleDetail): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.errorUploadSchedules = '';
            schedulesAdapter
                .submitSchedules(accountId, file, fileUploadDetails)
                .then((jobId: string) => {
                    return resolve(jobId);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to upload the Schedules file';
                    this.errorUploadSchedules = errorMessage;
                    this.isSchedulesUploading = false;
                    return reject(errorMessage);
                });
            this.isSchedulesUploading = true;
        });
    };
    //#endregion

    //#region getSubmitSchedulesStatus
    @action
    getSubmitSchedulesStatus = (jobId: string): Promise<SchedulesJobStatus> => {
        return new Promise<SchedulesJobStatus>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.errorUploadSchedules = '';
            schedulesAdapter
                .getSubmitSchedulesStatus(jobId)
                .then((jobStatus: SchedulesJobStatus) => {
                    const { completion } = jobStatus;
                    if (completion === 100) {
                        this.isSchedulesUploading = false;
                    }
                    return resolve(jobStatus);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to get the upload progress from Schedules file';
                    this.errorUploadSchedules = errorMessage;
                    this.isSchedulesUploading = false;
                    return reject(errorMessage);
                });
            this.isSchedulesUploading = true;
        });
    };
    //#endregion

    //#region getTaskTab
    @action
    getTasks = (limit = 10, offset = 0, filters?: TaskFilterParameters): Promise<Tasks> => {
        return new Promise<Tasks>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { tasksAdapter },
            } = this.rootStore;

            this.errorTasksTab = '';
            tasksAdapter
                .getTasks(limit, offset, filters)
                .then((tab: TasksTab) => {
                    this.isTasksTabLoading = false;
                    const { tasks } = tab;
                    return resolve(tasks);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to load the Tasks tab';
                    this.errorTasksTab = errorMessage;
                    this.isTasksTabLoading = false;
                    return reject(errorMessage);
                });
            this.isTasksTabLoading = true;
        });
    };
    //#endregion

    //#region getPCNList
    @action
    getPCNList = (
        limit = 10,
        offset = 0,
        filters?: PCNFiltersParameters
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isPcnRoutesLoading = true;
            schedulesAdapter
                .getPCNList(limit, offset, filters)
                .then((response: PCNRoutes) => {
                    const { total, rows } = response;
                    this.isPcnRoutesLoading = false;
                    this.pcnRoutesTotal = total;
                    this.pcnRoutes = rows;
                    return resolve();
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve route list';
                    this.error = error;
                    this.isPcnRoutesLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getPCNListToExport
    @action
    getPCNListToExport = (
        filters?: PCNFiltersParameters
    ): Promise<{total: number, rows: PCNList[]}> => {
        return new Promise<{total: number, rows: PCNList[]}>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isPcnRoutesLoading = true;
            schedulesAdapter
                .getPCNList(10000, 0, filters)
                .then((response: PCNRoutes) => {
                    const { total, rows } = response;
                    this.isPcnRoutesLoading = false;
                    return resolve({total, rows});
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const error = 'Unable to retrieve route list for export';
                    this.error = error;
                    this.isPcnRoutesLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getPCNFilterList
    @action
    getPCNFilterList = (
        filters: PCNFiltersParameters,
    ): Promise<PCNRoutes> => {
        return new Promise<PCNRoutes>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isPcnRoutesLoading = true;
            schedulesAdapter
                .getPCNList(0, 0, filters)
                .then((response: PCNRoutes) => {
                    this.isPcnRoutesLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve route filter list';
                    this.error = error;
                    this.isPcnRoutesLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getMarkersCoordinates
    @action
    getMarkersCoordinates = (folderId: string): Promise<PCNMarkerCoords[]> => {
        return new Promise<PCNMarkerCoords[]>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isPcnMapLoading = true;
            schedulesAdapter
                .getMarkersCoordinates(folderId)
                .then((markers: PCNMarkerCoords[]) => {
                    this.isPcnMapLoading = false;
                    return resolve(markers);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve marker coordinates list';
                    this.error = error;
                    this.isPcnMapLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region getFloorplanImage
    @action
    getFloorplanImage = (folderId: string): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            if (!this.currentUserTeam) {
                return reject('User team is not defined.');
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;
            const registeredUser = this.loggedInUser;

            if (!registeredUser) {
                const error = SESSION_EXPIRED_ERROR_TEXT;
                this.error = error;
                return reject(error);
            }
            this.isPcnMapLoading = true;
            schedulesAdapter
                .getFloorplanImage(folderId)
                .then((image: string) => {
                    this.isPcnMapLoading = false;
                    return resolve(image);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    this.clearTeamMarkersSearch();
                    const error = 'Unable to retrieve floorplan image';
                    this.error = error;
                    this.isPcnMapLoading = false;
                    return reject(error);
                });
        });
    };
    //#endregion

    //#region submitAnnotation
    @action
    submitAnnotation = (plannedTaskId: number, annotationData: AnnotationData): Promise<Annotations> => {
        return new Promise<Annotations>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { tasksAdapter },
            } = this.rootStore;

            this.errorAnnotation = '';
            tasksAdapter
                .submitAnnotation(plannedTaskId, annotationData)
                .then((response) => {
                    const { annotations } = response;
                    this.isAnnotationLoading = false;
                    return resolve(annotations);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to submit Annotation';
                    this.errorAnnotation = errorMessage;
                    this.isAnnotationLoading = false;
                    return reject(errorMessage);
                });
            this.isAnnotationLoading = true;
        });
    };
    //#endregion

    //#region getAnnotation
    @action
    getAnnotation = (annotationId: number): Promise<Annotations> => {
        return new Promise<Annotations>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { tasksAdapter },
            } = this.rootStore;

            this.errorAnnotation = '';
            tasksAdapter
                .getAnnotation(annotationId)
                .then((response) => {
                    const { annotations } = response;
                    this.isAnnotationLoading = false;
                    return resolve(annotations);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to get Annotation';
                    this.errorAnnotation = errorMessage;
                    this.isAnnotationLoading = false;
                    return reject(errorMessage);
                });
            this.isAnnotationLoading = true;
        });
    };
    //#endregion

    //#region completeAnnotation
    @action
    completeAnnotation = (annotationId: number, annotationData: AnnotationData): Promise<Annotations> => {
        return new Promise<Annotations>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { tasksAdapter },
            } = this.rootStore;

            this.errorAnnotation = '';
            tasksAdapter
                .completeAnnotation(annotationId, annotationData)
                .then((response) => {
                    const { annotations } = response;
                    this.isAnnotationLoading = false;
                    return resolve(annotations);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to complete Annotation';
                    this.errorAnnotation = errorMessage;
                    this.isAnnotationLoading = false;
                    return reject(errorMessage);
                });
            this.isAnnotationLoading = true;
        });
    };
    //#endregion

    //#region getCustomFilterValues
    @action
    getCustomFilterValues = (limit = 10, offset = 0, filters?: CustomFiltersParameters): Promise<string[]> => {
        return new Promise<string[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { completionAdapter },
            } = this.rootStore;

            completionAdapter
                .getCustomFilterValues(limit, offset, filters)
                .then((values: CustomFilterValues) => {
                    const { data } = values;
                    return resolve(data);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to load the Custom filters';
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region getFolderFiltersValues
    @action
    getFolderFiltersValues = (tabModule: string): Promise<FolderFilterValues[]> => {
        return new Promise<FolderFilterValues[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { completionAdapter },
            } = this.rootStore;

            completionAdapter
                .getFolderFiltersValues(tabModule)
                .then((values: CustomFilterValues) => {
                    const { data } = values;
                    const folders = data.map(e => {
                        return {
                            folder_id: e[0],
                            name: e[1]
                        } as FolderFilterValues;
                    });
                    return resolve(folders);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to load the Folder filters';
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region setScheduleFilters
    @action
    setScheduleFilters = (scheduleFilters: ScheduleFilters | undefined): void => {
        if (!scheduleFilters) {
            this.clearScheduleFilters();
            return;
        }

        this.pScheduleFilters = scheduleFilters;
        this.saveScheduleFilters(scheduleFilters);
    };

    //#region setPlannedTaskFilters
    @action
    setPlannedTaskFilters = (plannedTaskFilters: PlannedTaskFilters | undefined): void => {
        if (!plannedTaskFilters) {
            this.clearPlannedTaskFilters();
            return;
        }

        this.pPlannedTasksFilters = plannedTaskFilters;
        this.savePlannedTaskFilters(plannedTaskFilters);
    };
    //#endregion

    //#region createScheduleRoute
    @action
    createScheduleRoute = (rows: GridRowsProp | ValidAgGridRowModel, secondaryIdrowId: number):  Promise<JobCreationResult> => {
        return new Promise<JobCreationResult>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .submitSchedulesRoute(rows, secondaryIdrowId)
                .then((response: JobCreationResult) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    Bugsnag.notify((new Error(`Routes - Updating Schedule Issue`)), event => {
                        event.severity = 'error';
                        event.context = 'CPS Routes';
                        event.addMetadata('update issue', {
                            user_id: registeredUser.userId,
                            error_data: errorData,
                            payload_data: rows
                        });
                    });
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to create a new Schedule route';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
            this.isRoutesTabLoading = true;

        });
    };
    //#endregion

    //#region createSchedulePcn
    @action
    createSchedulePcn = (pcnValue: string, folderId: string, shiftLabel: string):  Promise<EntityPCN> => {
        return new Promise<EntityPCN>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .createSchedulePcn(pcnValue, folderId, shiftLabel)
                .then((response: EntityPCN) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    Bugsnag.notify((new Error(`Routes - Creating route Issue`)), event => {
                        event.severity = 'error';
                        event.context = 'CPS Routes';
                        event.addMetadata('creation issue', {
                            user_id: registeredUser.userId,
                            pcn_value: pcnValue,
                            error_data: errorData,
                            folder_id: folderId
                        });
                    });
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to create a new route';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region editSchedulePcn
    @action
    editSchedulePcn = (pcnId: string, folderId: string, pcnValue?: string, shiftLabel?: string):  Promise<JobCreationResult | string> => {
        return new Promise<JobCreationResult | string>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .editSchedulePcn(pcnId, folderId, pcnValue, shiftLabel)
                .then((response: JobCreationResult | string) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    Bugsnag.notify((new Error(`Routes - Editing route Issue`)), event => {
                        event.severity = 'error';
                        event.context = 'CPS Routes';
                        event.addMetadata('edition issue', {
                            user_id: registeredUser.userId,
                            pcnId: pcnId,
                            error_data: errorData,
                            folder_id: folderId
                        });
                    });
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to edit route';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region deleteSchedulePcn
    @action
    deleteSchedulePcn = (pcnId: string, folderId: string):  Promise<JobCreationResult> => {
        return new Promise<JobCreationResult>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .deleteSchedulePcn(pcnId)
                .then((response: JobCreationResult) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    Bugsnag.notify((new Error(`Routes - Deleting route Issue`)), event => {
                        event.severity = 'error';
                        event.context = 'CPS Routes';
                        event.addMetadata('deletion issue', {
                            user_id: registeredUser.userId,
                            pcnId: pcnId,
                            error_data: errorData,
                            folder_id: folderId
                        });
                    });
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to delete route';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
            this.isRoutesTabLoading = true;
        });
    };
    //#endregion

    //#region addUserToPcnUserList
    @action
    addUserToPcnUserList = (secondaryIdrowId: number, userId: string, startTime: number, endTime?: number):  Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .addUserToPcnUserList(secondaryIdrowId, userId, startTime, endTime)
                .then((response: string) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to add a new user into list';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region getPcnUserList
    @action
    getPcnUserList = (pcnId: string, current_one?: boolean):  Promise<PCNAssignmentUser[]> => {
        return new Promise<PCNAssignmentUser[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .getPcnUserList(pcnId, current_one)
                .then((pcnUsers: PCNAssignmentUser[]) => {
                    this.isRoutesTabLoading = false;
                    return resolve(pcnUsers);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to get route user list';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region createScheduleUserList
    @action
    createScheduleUserList = (folderId: string, folderName: string, users: string[]):  Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .createScheduleUserList(folderId, folderName, users)
                .then((response: string) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to create a new user list';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region editScheduleUserList
    @action
    editScheduleUserList = (userListId: string, folderName: string, users: string[]):  Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            this.isRoutesTabLoading = true;
            schedulesAdapter
                .editScheduleUserList(userListId, folderName, users)
                .then((response: string) => {
                    this.isRoutesTabLoading = false;
                    return resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = `Unable to edit the Schedule user list with id ${userListId}`;
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
        });
    };
    //#endregion

    //#region getUsersRoutes
    @action
    getUsersRoutes = ():  Promise<SearchResults<ScheduleRoute>> => {
        return new Promise<SearchResults<ScheduleRoute>>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .getUsersRoutes()
                .then((response: SchedulesRoutesTab) => {
                    const { routes: { rows, total} } = response;
                    this.isRoutesTabLoading = false;
                    this.scheduleRoutes = rows;
                    this.scheduleRoutesTotal = total;
                    return resolve({rows, meta: {resultCount: total, totalCount: total}});
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to get users routes';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
            this.isRoutesTabLoading = true;
        });
    };
    //#endregion

    //#region getSchedulesRoutes
    @action
    getSchedulesRoutes = (folderId: string, secondaryIdrowId: string):  Promise<ScheduleRow[]> => {
        return new Promise<ScheduleRow[]>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .getSchedulesRoutes(folderId, secondaryIdrowId)
                .then((response: ScheduleRow[]) => {
                    this.isRoutesTabLoading = false;
                    this.schedulesRoutesRows = response;
                    return resolve(response);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    const errorMessage = 'Unable to get Schedule route detail';
                    this.isRoutesTabLoading = false;
                    return reject(errorMessage);
                });
            this.isRoutesTabLoading = true;
        });
    };
    //#endregion

    //#region Private methods
    private updateCurrentUserTeam = (
        currentUserTeam: UserTeam | undefined
    ): void => {
        this.pCurrentUserTeam = currentUserTeam;
        if (currentUserTeam && currentUserTeam.teamId) {
            setItemToLocalStorage(
                COOKIE_NAMES.CURRENT_TEAM,
                currentUserTeam.serialize(),
                true
            );
            const setErrorMessage = (errorMessage: string) => {
                const error = this.error;
                if (error) {
                    this.error = `${this.error} ${errorMessage}`;
                } else {
                    this.error = errorMessage;
                }
            };
            this.getTeam(currentUserTeam.teamId).catch(() => {
                const errorMessage = 'The module filter might not be working.';
                setErrorMessage(errorMessage);
            });
            this.getTeamModules(currentUserTeam.teamId).catch(() => {
                const errorMessage = 'The module filter might not be working.';
                setErrorMessage(errorMessage);
            });
        } else {
            deleteItemFromLocalStorage(COOKIE_NAMES.CURRENT_TEAM, true);
        }
    };

    private saveLoginAuthorization = (
        authorization: LoginAuthorization | undefined
    ) => {
        this.pLoginAuthorization = authorization;
        HttpConnectorAdapter.setAuthorization(authorization);
        if (authorization) {
            setItemToLocalStorage(
                COOKIE_NAMES.LOGIN_AUTHORIZATION,
                encryptText(authorization.serialize())
            );
        } else {
            deleteItemFromLocalStorage(COOKIE_NAMES.LOGIN_AUTHORIZATION);
        }
    };

    private loadLoginAuthorization = (): LoginAuthorization | undefined => {
        try {
            const encryptedData = getItemFromLocalStorage(
                COOKIE_NAMES.LOGIN_AUTHORIZATION
            );
            if (!encryptedData) {
                return;
            }

            const serializedAuthorization = decryptText(encryptedData);
            const loginAuthorization =
                LoginAuthorization.createFromSerializedData(
                    serializedAuthorization
                );
            return loginAuthorization;
        } catch {
            return;
        }
    };

    private loadRegisteredUser = (): RegisteredUser | undefined => {
        try {
            const encryptedData = getItemFromLocalStorage(
                COOKIE_NAMES.REGISTERED_USER
            );
            if (!encryptedData) {
                return;
            }

            const serializedRegistration = decryptText(encryptedData);
            const registeredUser = RegisteredUser.createFromSerializedData(
                serializedRegistration
            );
            return registeredUser;
        } catch {
            return;
        }
    };

    private clearRegisteredUser = () => {
        this.pLoggedInUser = undefined;
        HttpConnectorAdapter.setRegisteredUser(undefined);
        deleteItemFromLocalStorage(COOKIE_NAMES.REGISTERED_USER);
    };

    private setRegisteredUser = (registeredUser: RegisteredUser): boolean => {
        const { valid } = registeredUser;

        if (!valid) {
            this.clearRegisteredUser();
            return false;
        }

        this.pLoggedInUser = registeredUser;
        setItemToLocalStorage(
            COOKIE_NAMES.REGISTERED_USER,
            encryptText(registeredUser.serialize())
        );
        return true;
    };

    private onSSORefreshToken = (registeredUser: RegisteredUser) => {
        this.setRegisteredUser(registeredUser);
    };

    private parseLoginResults = (
        registeredUser: RegisteredUser,
        password?: string
    ): boolean => {
        const successfullySavedRegisteredUser =
            this.setRegisteredUser(registeredUser);
        if (successfullySavedRegisteredUser) {
            HttpConnectorAdapter.setRegisteredUser(registeredUser);
            HttpConnectorAdapter.onSSORefreshToken = this.onSSORefreshToken;
            if (
                registeredUser.isVerificationNeeded &&
                password &&
                password.trim().length > 0
            ) {
                setItemToLocalStorage(
                    COOKIE_NAMES.USER_VERIFICATION_PASSWORD,
                    encryptText(password)
                );
                this.setCurrentLoginWizardStep(
                    LoginWizardSteps.ValidateAccount
                );
            } else {
                this.rootStore.routerStore.goTo(ROUTE_NAMES.HOME, {});
            }
        }
        return successfullySavedRegisteredUser;
    };

    private ssoLogout = (validationId?: string) => {
        if (!validationId) {
            return;
        }

        const {
            adapters: { loginAuthorizationAdapter },
        } = this.rootStore;
        loginAuthorizationAdapter
            .ssoLogout(validationId)
            .then((redirectUrl) => {
                this.pSessionExpired = false;
                window.location.href = redirectUrl;
            })
            .catch(() => {
                this.pSessionExpired = false;
                this.rootStore.routerStore.goTo(ROUTE_NAMES.LOGIN, {});
            });
    };

    private clearReportFilters = () => {
        this.pReportFilters = undefined;
        deleteItemFromLocalStorage(COOKIE_NAMES.REPORT_FILTERS, true);
    };

    private loadReportFilters = (): ReportFilters | undefined => {
        try {
            const encryptedData = getItemFromLocalStorage(
                COOKIE_NAMES.REPORT_FILTERS,
                true
            );
            if (!encryptedData) {
                return;
            }
            try {
                const serializedData = decryptText(encryptedData);
                const data = JSON.parse(serializedData);
                const {
                    assignedTo,
                    datePickerDateRange: datePickerDateRangeRaw,
                    folderId,
                    initialSelectedItem: initialSelectedItemRaw,
                    markerId,
                    searchReportText,
                    statusSelectedIds,
                    typeSelectedIds,
                } = data;

                // Deserialize datePickerDateRange
                let datePickerDateRange: CCDatePickerDateRange | undefined;
                if (datePickerDateRangeRaw) {
                    const deserializedDatePickerDateRange = JSON.parse(
                        datePickerDateRangeRaw
                    );
                    const {
                        buttonType: buttonTypeRaw,
                        from: fromRaw,
                        to: toRaw,
                    } = deserializedDatePickerDateRange;

                    datePickerDateRange = {
                        buttonType:
                            !!buttonTypeRaw && !isNaN(Number(buttonTypeRaw))
                                ? (JSON.parse(buttonTypeRaw) as ButtonType)
                                : undefined,
                        from:
                            !!fromRaw && !isNaN(Number(fromRaw))
                                ? new Date(fromRaw)
                                : undefined,
                        to:
                            !!toRaw && !isNaN(Number(toRaw))
                                ? new Date(toRaw)
                                : undefined,
                    } as CCDatePickerDateRange;
                }

                return {
                    assignedTo,
                    datePickerDateRange,
                    folderId,
                    initialSelectedItem:
                        this.createNavigationListItemFromSerializedData(
                            initialSelectedItemRaw
                        ),
                    markerId,
                    searchReportText,
                    statusSelectedIds,
                    typeSelectedIds,
                } as ReportFilters;
            } catch (error) {
                return;
            }
        } catch (error) {
            return;
        }
    };

    private createNavigationListItemFromSerializedData = (
        serializedData: string
    ): NavigationListItem | undefined => {
        if (!serializedData) {
            return undefined;
        }

        try {
            const data = JSON.parse(serializedData);
            const {
                folderData: folderDataRaw,
                folderDataTotal,
                id,
                isFolder,
                label,
            } = data;
            const folderData: NavigationListItem | undefined = folderDataRaw
                ? folderDataRaw.map((item: string) => {
                    return this.createNavigationListItemFromSerializedData(item);
                })
                : undefined;

            return {
                folderData,
                folderDataTotal,
                id,
                isFolder,
                label,
            } as NavigationListItem;
        } catch (error) {
            return;
        }
    };

    private serializeNavigationListItem = (
        navigationListItem: NavigationListItem | undefined
    ): string => {
        let serializedItem = '';
        try {
            if (navigationListItem) {
                const { folderData, folderDataTotal, id, isFolder, label } =
                    navigationListItem;
                const serializedFolderData = folderData
                    ? folderData.map((item: NavigationListItem) => {
                        return this.serializeNavigationListItem(item);
                    })
                    : [];

                serializedItem = JSON.stringify({
                    folderData: serializedFolderData,
                    folderDataTotal,
                    id,
                    isFolder,
                    label,
                });
            }
        } catch (error) {
            return serializedItem;
        }

        return serializedItem;
    };

    private saveReportFilters = (reportFilters: ReportFilters) => {
        if (!reportFilters) {
            return;
        }
        try {
            // Serialize the report filters creating a copy that
            // is json friendly.
            const {
                assignedTo,
                datePickerDateRange,
                folderId,
                initialSelectedItem,
                markerId,
                searchReportText,
                statusSelectedIds,
                typeSelectedIds,
            } = reportFilters;

            // Serialize datePickerDateRange
            let serializedDatePickerDateRange = '';
            if (datePickerDateRange) {
                const { buttonType, from, to } = datePickerDateRange;
                serializedDatePickerDateRange = JSON.stringify({
                    buttonType: JSON.stringify(buttonType),
                    from: from ? from.getTime() : '',
                    to: to ? to.getTime() : '',
                });
            }

            const dataToSerialize = {
                assignedTo: assignedTo ? assignedTo : '',
                statusSelectedIds: [...statusSelectedIds],
                typeSelectedIds: typeSelectedIds ? typeSelectedIds : [],
                datePickerDateRange: serializedDatePickerDateRange,
                searchReportText: searchReportText ? searchReportText : '',
                markerId: markerId ? markerId : '',
                folderId: folderId ? folderId : '',
                initialSelectedItem:
                    this.serializeNavigationListItem(initialSelectedItem),
            };
            const serializedData = JSON.stringify(dataToSerialize);

            setItemToLocalStorage(
                COOKIE_NAMES.REPORT_FILTERS,
                encryptText(serializedData),
                true
            );
        } catch (error) {
            return;
        }
    };

    private clearScheduleFilters = () => {
        this.pScheduleFilters = undefined;
        deleteItemFromLocalStorage(COOKIE_NAMES.SCHEDULE_FILTERS, true);
    };

    private loadScheduleFilters = (): ScheduleFilters | undefined => {
        try {
            const encryptedData = getItemFromLocalStorage(
                COOKIE_NAMES.SCHEDULE_FILTERS,
                true
            );

            if (!encryptedData){
                return;
            }

            try {
                const serializedData = decryptText(encryptedData);
                const data = JSON.parse(serializedData);
                const {
                    filterByDateTimestamp,
                    statusSelectedIds,
                    folderToFilter,
                    propertyIdToFilter
                } = data;

                const filterByDate = filterByDateTimestamp ? new Date(filterByDateTimestamp) : undefined;
                return {
                    filterByDate,
                    statusSelectedIds,
                    folderToFilter,
                    propertyIdToFilter
                } as ScheduleFilters;

            } catch (error) {
                return;
            }
        } catch (error) {
            return;
        }
    };

    private saveScheduleFilters = (scheduleFilters: ScheduleFilters) => {
        if (!scheduleFilters) {
            return;
        }
        try {
            // Serialize the schedule filters creating a copy that
            // is json friendly.
            const {
                filterByDate,
                statusSelectedIds,
                folderToFilter,
                propertyIdToFilter
            } = scheduleFilters;

            // Serialize date
            let serializedFilterByDate = undefined;
            if (filterByDate) {
                if (typeof filterByDate === 'string') {
                    serializedFilterByDate = filterByDate;
                } else {
                    serializedFilterByDate = filterByDate.getTime();
                }
            }

            const dataToSerialize = {
                filterByDate: serializedFilterByDate ? serializedFilterByDate : undefined,
                statusSelectedIds: [...statusSelectedIds],
                folderToFilter: folderToFilter ? folderToFilter : '',
                propertyIdToFilter: propertyIdToFilter ? propertyIdToFilter : ''
            };
            const serializedData = JSON.stringify(dataToSerialize);

            setItemToLocalStorage(
                COOKIE_NAMES.SCHEDULE_FILTERS,
                encryptText(serializedData),
                true
            );
        } catch (error) {
            return;
        }
    };

    private clearPlannedTaskFilters = () => {
        this.pPlannedTasksFilters = undefined;
        deleteItemFromLocalStorage(COOKIE_NAMES.PLANNED_TASK_FILTERS, true);
    };

    private loadPlannedTaskFilters = (): PlannedTaskFilters | undefined => {
        try {
            const encryptedData = getItemFromLocalStorage(
                COOKIE_NAMES.PLANNED_TASK_FILTERS,
                true
            );

            if (!encryptedData){
                return;
            }

            try {
                const serializedData = decryptText(encryptedData);
                const data = JSON.parse(serializedData);
                const {
                    hasUserDocFilters,
                    datePickerDateRange: datePickerDateRangeRaw,
                    statusSelectedIds,
                    folderToFilter,
                    propertyIdToFilter,
                    reviewStatusSelectedIds,
                    customSelectedFilters
                } = data;

                let datePickerDateRange: CCDatePickerDateRange | undefined;
                if (datePickerDateRangeRaw) {
                    const deserializedDatePickerDateRange = JSON.parse(
                        datePickerDateRangeRaw
                    );
                    const {
                        buttonType: buttonTypeRaw,
                        from: fromRaw,
                        to: toRaw,
                    } = deserializedDatePickerDateRange;

                    datePickerDateRange = {
                        buttonType:
                            !!buttonTypeRaw && !isNaN(Number(buttonTypeRaw))
                                ? (JSON.parse(buttonTypeRaw) as ButtonType)
                                : undefined,
                        from:
                            !!fromRaw && !isNaN(Number(fromRaw))
                                ? new Date(fromRaw)
                                : undefined,
                        to:
                            !!toRaw && !isNaN(Number(toRaw))
                                ? new Date(toRaw)
                                : undefined,
                    } as CCDatePickerDateRange;
                }
                
                return {
                    hasUserDocFilters,
                    datePickerDateRange,
                    statusSelectedIds,
                    folderToFilter,
                    propertyIdToFilter,
                    reviewStatusSelectedIds,
                    customSelectedFilters,
                } as PlannedTaskFilters;

            } catch (error) {
                return;
            }
        } catch (error) {
            return;
        }
    };

    private savePlannedTaskFilters = (plannedTaskFilters: PlannedTaskFilters) => {
        if (!plannedTaskFilters) {
            return;
        }
        try {
            // Serialize the schedule filters creating a copy that
            // is json friendly.
            const {
                hasUserDocFilters,
                datePickerDateRange,
                statusSelectedIds,
                folderToFilter,
                propertyIdToFilter,
                reviewStatusSelectedIds,
                customSelectedFilters
            } = plannedTaskFilters;

            let serializedDatePickerDateRange = '';
            if (datePickerDateRange) {
                const { buttonType, from, to } = datePickerDateRange;
                serializedDatePickerDateRange = JSON.stringify({
                    buttonType: JSON.stringify(buttonType),
                    from: from ? from.getTime() : '',
                    to: to ? to.getTime() : '',
                });
            }
            const dataToSerialize = {
                hasUserDocFilters,
                datePickerDateRange: serializedDatePickerDateRange,
                statusSelectedIds: [...statusSelectedIds],
                folderToFilter: folderToFilter ? folderToFilter : '',
                propertyIdToFilter: propertyIdToFilter ? propertyIdToFilter : '',
                reviewStatusSelectedIds: [...reviewStatusSelectedIds],
                customSelectedFilters: [...customSelectedFilters],
            };
            const serializedData = JSON.stringify(dataToSerialize);

            setItemToLocalStorage(
                COOKIE_NAMES.PLANNED_TASK_FILTERS,
                encryptText(serializedData),
                true
            );
        } catch (error) {
            return;
        }
    };

    //#region setMarkersList
    setCurrentJobs = (tasks: RunningJobs[], page: MainTabs) => {
        const storedTasks = this.currentStoredJobs;
        if (!storedTasks) return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        storedTasks.find(e => e.page === page)!.tasks = tasks ? tasks : [];
        this.currentStoredJobs = storedTasks;
    };
    //#endregion

    //#region getRunningJobStatus
    @action
    getRunningJobStatus = (jobId: string): Promise<JobStatusResult> => {
        return new Promise<JobStatusResult>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .getSubmitSchedulesStatus(jobId)
                .then((jobStatus: JobStatusResult) => {
                    return resolve(jobStatus);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    return reject(errorData);
                });
        });
    };
    //#endregion

    //#region getRunningJobError
    @action
    getRunningJobError = (jobId: string): Promise<JobErrorResult> => {
        return new Promise<JobErrorResult>((resolve, reject) => {
            const registeredUser = this.loggedInUser;
            if (!registeredUser) {
                this.error = SESSION_EXPIRED_ERROR_TEXT;
                return reject(SESSION_EXPIRED_ERROR_TEXT);
            }

            const {
                adapters: { schedulesAdapter },
            } = this.rootStore;

            schedulesAdapter
                .getSubmitSchedulesError(jobId)
                .then((jobStatus: JobErrorResult) => {
                    return resolve(jobStatus);
                })
                .catch((errorData) => {
                    if (errorData) {
                        const { error: responseError, response } = errorData;

                        if (
                            response?.status === 401 ||
                            (registeredUser.isSSO && responseError === -1)
                        ) {
                            this.pSessionExpired = true;
                        }
                    }
                    return reject(errorData);
                });
        });
    };
    //#endregion
}
