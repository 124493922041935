import Bugsnag from '@bugsnag/js';
import { SHA1 } from 'crypto-js';
import {
    API,
    CC_APP_TYPE
} from '../constants';
import {
    LoginAuthorization,
    LoginAuthorizationModel,
    RefreshSSOTokenResponseModel
} from '../models/login-authorization';
import {
    RegisteredUser, 
    RegisteredUserModel,
    UserPermissions
} from '../models/registered-user';
import {
    Account,
    EntityAccount,
    EntityUser,
    User
} from '../shared/domain';
import { HttpClient } from '../shared/utils';
import { HttpConnectorAdapter } from './http-connector-adapter';
import { LoginAuthorizationBaseAdapter } from './login-authorization-base-adapter';

let singletonInstance: LoginAuthorizationBaseAdapter;

export class LoginAuthorizationAdapter implements LoginAuthorizationBaseAdapter {

    static getAdapter(): LoginAuthorizationBaseAdapter {
        if (!singletonInstance) {
            singletonInstance = new LoginAuthorizationAdapter();
        }

        return singletonInstance;
    }

    async checkEmail(email: string): Promise<LoginAuthorization> {
        const params = {
            email, 
            cctype: CC_APP_TYPE
        };
        const response = await HttpClient.post(API.URL.AUTHENTICATION.CHECK_LOGIN, params);
        const { data: model } : { data: LoginAuthorizationModel } = response;

        return LoginAuthorization.create(model);
    }

    async login(email: string, password: string, token: string): Promise<RegisteredUser> {
        const params = {
            email, 
            password: SHA1(password).toString()
        };

        const {
            BASE_URL: CAPTCHA_VERIFY_URL,
            CLIENT_TYPE: CAPTCHA_CLIENT_TYPE
        } = API.URL.CAPTCHA;

        if (token && CAPTCHA_VERIFY_URL) {

            const captchaResponse = await HttpClient.post(
                CAPTCHA_VERIFY_URL,
                { token, client: CAPTCHA_CLIENT_TYPE.PORTAL3, version: 2 }
            );
            
            const {
                data: { success, error_code: errorCode },
                status
            } : { 
                data: {
                    success?: boolean,
                    error_code: string
                },
                status: number
            } = captchaResponse;

            if (!success || !!errorCode || status !== 200) {
                return Promise.reject({
                    response: {
                        data: {},
                        status
                    },
                    message: errorCode
                });
            }
        }
        const response = await HttpClient.post(API.URL.AUTHENTICATION.LOGIN, params);
        return RegisteredUser.create(response.data);
    }

    async loginJwt(jwt: string): Promise<RegisteredUser> {
        const {
            LOGIN_JWT
        } = API.URL.AUTHENTICATION;

        const params = { 
            encoded_jwt: jwt
        };
        const response = await HttpClient.post(LOGIN_JWT, params);
        const model = response.data as RegisteredUserModel;
        return RegisteredUser.create(model);
    }

    async loginMarkerProxy(markerId: string): Promise<RegisteredUser> {
        const {
            AUTHENTICATION: { LOGIN_PROXY }
        } = API.URL;

        const response = await HttpClient.get(`${LOGIN_PROXY}${markerId}`);
        const {
            data: responseData
        } = response;

        if (!responseData || !('email' in responseData)) {
            return Promise.reject(responseData);
        }

        return RegisteredUser.create(responseData as RegisteredUserModel);
    }

    async resetPassword(email: string): Promise<string> {
        const params = {
            email
        };
        const response = await HttpClient.post(API.URL.AUTHENTICATION.RESET, params);
        const {
            status
        } = response.data;

        return status;
    }

    async createNewAccount(username: string, email: string, password: string, accessCode?: string): Promise<RegisteredUser> {
        let params = {
            email,
            username,
            password
        };

        // If there is an access code it creates a manager user
        if (accessCode) {
            params = {
                ...params,
                ...{
                    forPortal: true,
                    access_code: accessCode
                }
            };
        }

        const response = await HttpClient.post(API.URL.AUTHENTICATION.REGISTER, params);
        const {
            data: responseData
        } = response;
        if (!responseData) {
            return Promise.reject();
        }

        return RegisteredUser.create(responseData.data as RegisteredUserModel);
    }

    async validateNewAccount(userId: string, code: number): Promise<unknown> {
        if (!userId || !code) {
            return Promise.reject();
        }

        const params = {
            user_id: userId,
            code
        };

        const response = await HttpClient.post(API.URL.AUTHENTICATION.VALIDATE, params);

        const {
            data: responseData
        } = response;

        if (!responseData) {
            return Promise.reject(response);
        }
        return responseData;
    }

    async checkSSOAuthorization(authorization: LoginAuthorization, code: string, state = ''): Promise<RegisteredUser> {
        if (!authorization.valid) {
            return Promise.reject({errorMessage: 'Invalid Login Authorization'});
        }

        if ((authorization.validationId !== state)) {
            return Promise.reject({errorMessage: 'ValidationId does not match the state parameter'});
        }

        const params = {
            code,
            state
        };
        const response = await HttpClient
            .get(API.URL.AUTHENTICATION.SSO_VALIDATE, params);

        if (response.status !== 200) {
            Bugsnag.notify((new Error('checkSSOAuthorization - Check SSO has an error')), (event) => {
                event.severity = 'error';
                event.context = 'SSO';
                event.addMetadata('sso_check', {
                    authorization: authorization,
                    code: code,
                    error: response
                });
            });

            return Promise.reject(false);
        }

        return RegisteredUser.createSSOUser(response.data);
    }
    
    async refreshSSOToken(validationId: string): Promise<RefreshSSOTokenResponseModel> {
        if (!validationId) {
            return Promise.reject({errorMessage: 'ValidationId cannot be empty.'});
        }

        const params = {
            validation_id: validationId
        };
        const response = await HttpClient
            .get(API.URL.AUTHENTICATION.SSO_REFRESH, params);

        if (response.status !== 200) {
            Bugsnag.notify((new Error('refreshSSOToken - SSO refresh has an error')), (event) => {
                event.severity = 'error';
                event.context = 'SSO';
                event.addMetadata('sso_refresh', {
                    validation_id: validationId,
                    error: response
                });
            });

            return Promise.reject(false);
        }

        return response.data as RefreshSSOTokenResponseModel;
    }

    async ssoLogout(validationId: string): Promise<string> {
        const params = {
            validation_id: validationId
        };
        const response = await HttpClient
            .get(API.URL.AUTHENTICATION.SSO_LOGOUT, params);

        if (response.status !== 200) {
            Bugsnag.notify((new Error('ssoLogout - SSO logout has an error')), (event) => {
                event.severity = 'error';
                event.context = 'SSO';
                event.addMetadata('sso_logout', {
                    validation_id: validationId,
                    error: response
                });
            });

            return Promise.reject(false);
        }
        const {
            data:{
                logout_url: logoutUrl
            }
        } = response;

        return logoutUrl;
    }

    async getUser(registeredUser: RegisteredUser, userId: string) : Promise<User> {
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }

        const {
            cookie,
            isSSO
        } = registeredUser;

        let customHeader;

        if (isSSO) {
            const { IdToken } = cookie;
            customHeader = {idToken: IdToken};
        }

        const response = await HttpConnectorAdapter.get(
            API.URL.USERS.USERS.replace(API.URL.Tokens.USERS.USER_ID, userId), undefined, customHeader
        );

        const {
            results: {
                user: model
            }
        } : { results: { user: EntityUser } } = response.data;
        return User.create(model);
    }

    async getUserPermissions(registeredUser: RegisteredUser, folderId: string): Promise<UserPermissions> {
        if (!registeredUser || !registeredUser.cookie) {
            return Promise.reject();
        }

        const response = await HttpConnectorAdapter.get(
            API.URL.USERS.USER_PERMISSIONS.replace(API.URL.Tokens.USERS.USER_ID, registeredUser.userId),
            { folder_id: folderId}
        );

        const { 
            status: responseStatus,
            message,
            data
        } : {
            status: boolean,
            message: string,
            data: UserPermissions
        } = response.data;

        if ((!responseStatus) || !data) {
            return Promise.reject(message);
        }

        return data;
    }

    async getAccount(teamId: string, userId: string) : Promise<Account> {
        if (!teamId || !userId) {
            return Promise.reject();
        }

        const response = await HttpConnectorAdapter.get(
            API.URL.ACCOUNTS.TEAM_BY_USER
                .replace(API.URL.Tokens.ACCOUNTS_TEAMS.TEAM_ID, teamId)
                .replace(API.URL.Tokens.ACCOUNTS_TEAMS.USER_ID, userId)
        );
        
        const { data: accountUserData } = response;
        const { account_id: accountId } = accountUserData;

        // Get Tabs from Account's details
        const accountDisplayTabs = {
            scheduling: undefined,
            tasking: undefined,
            annotation: undefined,
            moduleGroups: undefined,
            schedulingRoutes: undefined
        };
        const account = await HttpConnectorAdapter.get(
            API.URL.ACCOUNTS.ACCOUNT_BY_ID
                .replace(API.URL.Tokens.ACCOUNTS.ACCOUNT, accountId)
        );

        const accountMeta = account?.data?.data?.meta;
        const {
            scheduling,
            tasking,
            annotation,
            module_groups,
            scheduling_routes
        } = accountMeta;

        accountDisplayTabs['annotation'] = annotation;
        accountDisplayTabs['moduleGroups'] = module_groups;

        // Update and show tabs according to account and user authorization
        // TODO - This will be change according user's permissions
        accountDisplayTabs['scheduling'] = scheduling;
        accountDisplayTabs['tasking'] = tasking;
        accountDisplayTabs['schedulingRoutes'] = scheduling_routes;

        const model: EntityAccount = {...accountUserData, ...accountDisplayTabs} as EntityAccount;
        return Account.create(model);
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<unknown> {
        if (!oldPassword || !newPassword) {
            return Promise.reject();
        }

        const params = {
            old_password: SHA1(oldPassword).toString(),
            new_password: newPassword
        };

        const response = await HttpConnectorAdapter.post(API.URL.AUTHENTICATION.CHANGE_PASSWORD, params);

        const {
            data: responseData
        } = response;

        if (!responseData) {
            return Promise.reject(response);
        }
        return responseData;
    }
}
