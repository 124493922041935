/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'ag-grid-enterprise';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { RootStore } from '../../stores/root.store';
import { SideNavbar } from '../../shared/components/side-navbar';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Button,
    Divider,
    IconButton,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import { CCSnackbar, CCSnackbarVariant } from '../../shared/components/cc-snackbar';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { RenderTree } from '../../shared/components/node-tree';
import { SearchResults, SearchResultsUser, SearchResultsWithAccount } from '../../adapters/report-base-adapter';
import {
    EntityFolder,
    Floorplan,
    Folder,
    PCNList,
    PCNMarkerCoords,
    ScheduleRow,
    UserTeam,
    EntityPCN
} from '../../shared/domain';
import { CC_ROUTES, REPORTS } from '../../constants';
import { NavigationListItem } from '../../components/navigation-list';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { FolderTree } from '../../components/folder-tree';
import { JobCreationResult, JobTypes, RunningJobs, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../models/jobs';
import { MainTabs } from '../../stores/manager.store';
import { RunningJobsList } from '../../components/running-jobs-list';
import { PageBoundary, SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { HourglassBottom, PictureAsPdf } from '@mui/icons-material';
import { AgGridList, ValidAgGridRowModel } from '../../shared/components/ag-grid-list';
import { ColDef } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SimpleModal } from '../../shared/components/simple-modal';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import '../../../node_modules/leaflet/dist/leaflet.css';
import L, { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import { ccLogoBottom } from '../../shared/assets/images/cc-logo';
import memoize from 'memoize-one';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { splitList } from '../../shared/utils/split-list';
import { CHICAGO_LOGO } from '../../shared/assets/images/chicago-logo';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserPermissions } from '../../models/registered-user';

dayjs.extend(timezone);
dayjs.extend(utc);

const ROUTE_CREATED_SUCCESFULLY = 'Route created successfully';
const FOLDER_PERMISSIONS = 'route_read,route_write';
const TASKS_ROWS_PER_PAGE = 5;
const TASKS_PAGING_LIMIT = 100;
const CC_ROUTES_PER_PAGE = 5;
const { CC_ROUTES_NAME } = CC_ROUTES;


const PDF_LOGO: {[key: string]: string } = {
    'PCN': CHICAGO_LOGO
};

type ModalTypeProp = 'DEFAULT' | 'NEW' | 'LIST';
const MODAL_TYPE = {
    DEFAULT: 'DEFAULT' as ModalTypeProp,
    NEW: 'NEW' as ModalTypeProp,
    LIST: 'LIST' as ModalTypeProp
};

const styles = (theme: Theme) => createStyles({
    divider: {
        margin: '1.6em 0 1.55em 0',
    },
    contentContainer: {
        '&, &$navbarContent': {
            padding: '1.45em',
        },
        [theme.breakpoints.between(
            'xs',
            theme.breakpoints.values.iPhone8_Landscape
        )]: {
            padding: '0',
        },
    },
    dataGridContainer: {
        padding: '2em 2em 3.8em',
        width: 'calc(100% - 4em)',
        '&.navbar-opened': {
            width: 'calc(100% - 20.2em)',
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            })
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
            '& .MuiDataGrid-cell:focus': {
                outline: '0'
            }
        },
        '& .ag-checkbox-input-wrapper.ag-checked': {
            '&::before': {
                background: theme.palette.primary.main,
                transform: 'scale(2.2)',
                top: '2px'
            },
            '&::after': {
                color: 'white',
                transform: 'scale(1.8)',
                top: '1px'
            },
            '&.ag-disabled::before': {
                transform: 'scale(1.5)'
            }
        }
    },
    gridWrapper: {
        height: 'calc(100vh - 22em)'
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        margin: '0 2em'
    },
    errorMessage: {
        width: '100%',
        color: 'red',
        fontWeight: 400,
        textAlign: 'center',
        margin: '1em'
    },
    navbarIconContainer: {
        padding: '0.3em 0.4em',
        backgroundColor: theme.palette.secondary.main,
    },
    navbarIconContainerSmall: {
        transform: 'scale(0.7)',
        margin: '0.7em',
    },
    navbarIcon: {
        color: 'white',
    },
    navbarContent: {
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        width: '20em',
    },
    navbarTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    navbarTitle: {
        fontWeight: 'bold',
        marginLeft: '0.4em',
    },
    bodyTitle: {
        marginBottom: '0.4em',
    },
    modalContainer: {
        padding: '2em',
        maxWidth: '55em',
        width: '100%'
    },
    modalHeaderContainer: {
        position: 'relative',
        padding: '8px'
    },
    modalTitle: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBottom: '0.5em'
    },
    navbarComponentTitle: {
        color: theme.palette.grey[500],
        fontSize: '0.9em',
        fontWeight: 600,
        marginBottom: '0.3em',
    },
    modalCloseButton: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
    modalSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    modalBodyList: {
        padding: '0',
        listStyle: 'none',
        minWidth: '14em',
        '& li': {
            padding: '0.9em 0'
        },
        '& span': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    bodyLeftColumn: {
        width: '32em'
    },
    headerColumn: {
        padding: '0.3em 0',
        width: '22em'
    },
    moduleGroupField: {
        width: '100%',
        minWidth: '14.3em'
    },
    snackbar: {
        top: '7em',
    },
    navbarInput: {
        width: '100%',
        paddingLeft: '0.4em',
    },
    navbarPlaceholder: {
        fontSize: '0.9em',
    },
    routeNavButton: {
        width: '100%',
        height: '4em',
        marginBottom: '1em',
        '& .MuiSvgIcon-root': {
            marginRight: '0.2em',
            transform: 'scale(0.9)'
        }
    },
    treeContainer: {
        padding: '2em',
        flex: '1'
    },
    spinner: {
        zIndex: 1301,
        position: 'fixed',
    },
    tasksContainerSize: {
        flex: 1,
        '& .MuiListItem-root': {
            padding: '0.5em !important'
        }
    },
    topMargin: {
        marginTop: '2em'
    },
    editCell: {
        paddingLeft: '4px',
        '& .MuiSvgIcon-root': {
            position: 'relative',
            top: '0.25em',
            left: '0.2em'
        }
    },
    pcnModal: {
        minWidth: '30em',
        maxWidth: '35em',
        padding: '0.5em',
        borderRadius: '0.4em'
    },
    pcnModalFooter: {
        alignItems: 'center',
        flex: 1,
        padding: '1em',
        display: 'flex',
        justifyContent: 'space-between'
    },
    pcnModalTitle: {
        marginTop: '1em',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '0.2em',
        borderBottom: '1px solid'
    },
    pcnModalContent: {
        width: '100%',
        height: '100%'
    },
    pcnModalContainer: {
        padding: '1.5em 0 0',
        width: '100%',
        height: '100%'
    },
    pcnInput: {
        width: '100%',
        marginBottom: '1.5em'
    },
    pcnList: {
        marginTop: '1em',
        height: '26.1em',
        overflow: 'auto'
    },
    floorplanList: {
        marginTop: '1em',
        maxHeight: '16em',
        overflow: 'auto'
    },
    mapContainer: {
        padding: '2em',
        width: '100%',
        height: '90vh'
    },
    mapWrapper: {
        height: '92%',
        width: '100%',
        minHeight: '35em',
        minWidth: '50em',
        margin: '2em 0',
        border: '1px solid',
        borderColor: theme.palette.grey[500],

        '& #map': {
            height: '100%',
            width: '100%'
        },
        '& .marker-icon': {
            background: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
            border: '1px solid',
            borderRadius: '50%',
            boxShadow: '2px 2px 5px',
            height: '20px !important',
            width: '20px !important',

            '&:hover': {
                background: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main
            }
        },
        '& .marker-icon-green': {
            background: theme.ccPalette.cc_colors.solid.ccCentersilhouette.main,
            border: '1px solid',
            borderRadius: '50%',
            boxShadow: '2px 2px 5px',
            height: '20px !important',
            width: '20px !important',

            '&:hover': {
                background: theme.ccPalette.cc_colors.solid.ccRightsilhouette.main
            }
        },
        '& .leaflet-popup-content': {
            fontWeight: 800
        },
        '& .leaflet-tooltip-pane': {
            fontWeight: 800
        }
    },
    modal: {
        position: 'absolute',
        padding: '3em'
    },
    gridActionsBtn: {
        '& .MuiSvgIcon-root': {
            marginRight: '0.3em'
        }
    },
    pcnActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    totalMarkersSelected: {
        flex: 'auto',
        fontWeight: '600',
        fontStyle: 'italic',
        fontSize: '1rem'
    }
});

interface SchedulingRoutesDic {
    portal3_tab: {
        default_grid_values: { [key: string]: string };
        route_columns_definition: ColDef[];
        shift_labels: GridUser[];
        default_pcl_team_id: string;
    };
}

interface changeModulePermission {
    data: { change_module_group_permission: boolean };
    status: boolean;
}

interface GridUser {
    value: string,
    label: string
}

interface LocationFilterCallbackTypeWithAccount<T> {
    data: ReadonlyArray<T>;
    total: number;
    accountId: string;
    accountName: string;
}

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    userTeam?: UserTeam;
}

interface States {
    errorMessage: string;
    totalRows: number;
    isRoutesDrawerOpen: boolean;
    snackbarVariant: CCSnackbarVariant;
    snackbarMessage: string;
    snackbarOpen: boolean;
    dialogOpen: boolean;
    dialogTitle: string;
    dialogMessage: string;
    treeFolders: RenderTree;
    treeFoldersClicked: string[];
    treeFoldersExpanded: string[];
    gridRows: ValidAgGridRowModel[];
    gridColumns: ColDef[];
    excludeColumnForFill: string[];
    isCreateNewRoute: boolean;
    showUpdateCategory: boolean;
    selectedFolder: EntityFolder | undefined;
    selectedPcn: EntityPCN | undefined;
    users: SearchResultsUser[];
    userListId: string;
    isPreviewGridMode: boolean;
    isNewSchedule: boolean;
    runningJobs: RunningJobs[];
    currentJobsOffset: number;
    isPcnModalOpen: boolean;
    currentPcn: string;
    pcnField: string;
    currentShiftLabel: string;
    isMapsSectionOpen: boolean;
    floorplanList: SimpleListItem[];
    selectedFloorplanId: string | undefined;
    pcnOffset: number;
    pcnPage: number;
    isGridUpdated: boolean;
    openSaveGridModal: boolean;
    closeSaveGridModalCallback: () => void;
    isOpenEditPCNModal: boolean;
    isOpenDeletePCNModal: boolean;
    isShiftLabelEdit: boolean;
    pcnModalType: ModalTypeProp
    showDeletePCNBtn: boolean;
    showCreateEditPCNButtons: boolean;
    routeMarkersSelected: number;
}

@inject('rootStore')
@observer
class RoutesTab extends React.Component<Props, States> {
    public static defaultProps = {
    };
    
    state: States = {
        errorMessage: '',
        totalRows: 0,
        isRoutesDrawerOpen: true,
        snackbarVariant: 'info' as CCSnackbarVariant,
        snackbarMessage: '',
        snackbarOpen: false,
        dialogOpen: false,
        dialogTitle: '',
        dialogMessage: '',
        treeFolders: {
            id: 'treeRoot',
            name: 'Folders',
            children: [],
        },
        treeFoldersClicked: [],
        treeFoldersExpanded: [],
        gridRows: [],
        gridColumns: [],
        excludeColumnForFill: [],
        isCreateNewRoute: false,
        showUpdateCategory: false,
        selectedFolder: undefined as EntityFolder | undefined,
        selectedPcn: undefined as EntityPCN | undefined,
        users: [] as SearchResultsUser[],
        userListId: '',
        isPreviewGridMode: false,
        isNewSchedule: false,
        runningJobs: [],
        currentJobsOffset: 0,
        isPcnModalOpen: false,
        currentPcn: '',
        pcnField: '',
        currentShiftLabel: '',
        isMapsSectionOpen: false,
        floorplanList: [],
        selectedFloorplanId: undefined,
        pcnOffset: 0,
        pcnPage: 0,
        isGridUpdated: false,
        openSaveGridModal: false,
        isOpenEditPCNModal: false,
        isOpenDeletePCNModal: false,
        isShiftLabelEdit: false,
        pcnModalType: 'DEFAULT',
        showDeletePCNBtn: false,
        showCreateEditPCNButtons: false,
        routeMarkersSelected: 0,
        closeSaveGridModalCallback: function() {
            return; // empty callback as default
        }
    };

    defaultGridValues: {[key: string]: string} = {};
    defaultShiftLabel = '';
    map = undefined as L.Map | undefined;
    shiftLabels: GridUser[] = [];

    componentDidMount() {
        const {
            rootStore: { managerStore },
            userTeam,
        } = this.props;
        const {
            error,
            currentAccount
        } = managerStore;

        const schedulingRoutes = currentAccount?.schedulingRoutes as SchedulingRoutesDic | undefined;
        const portal3Tab = schedulingRoutes?.portal3_tab;

        if (portal3Tab) {
            this.fetchRunningJobs();
            const gridColumns = this.convertToColumns(portal3Tab.route_columns_definition);
            this.defaultGridValues = portal3Tab.default_grid_values;
            this.shiftLabels = portal3Tab.shift_labels;
            const excludeColumnForFill = gridColumns
                .filter((e) => Object(e).hasOwnProperty('editable'))
                .map((e) => e.field!);

            this.setState({ gridColumns, excludeColumnForFill});

            if (this.shiftLabels.length) {
                this.defaultShiftLabel = this.shiftLabels[0].value;
                this.setState({ currentShiftLabel: this.defaultShiftLabel });
            }
        }

        if (userTeam) {
            this.fetchFoldersTree();
            managerStore
                .checkChangeCategoryByTeam(userTeam.teamId)
                .then((response) => {
                    const { data } =
                        response as unknown as changeModulePermission;
                    if (data.change_module_group_permission) {
                        this.setState({ showUpdateCategory: true });
                    } else {
                        this.setState({ showUpdateCategory: false });
                    }
                })
                .catch((error) => {
                    this.showError(error);
                });
        }

        if (error) {
            this.showError(error);
            managerStore.error = '';
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { userTeam: currentUserTeam } = this.props;
        if (currentUserTeam && prevProps.userTeam !== currentUserTeam) {
            this.refreshTeam(currentUserTeam);
        }
    }

    componentWillUnmount() {
        const { runningJobs } = this.state;
        this.clearIntervals(runningJobs);
    }

    clearIntervals(tasks: RunningJobs[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    fetchRunningJobs = () => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const tasks = managerStore.currentStoredJobs.find(e => e.page === MainTabs.Routes);

        if (!tasks) {
            return;
        }

        this.setState({
            runningJobs: tasks.tasks
        }, () => {
            tasks.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    };


    startTaskWatcher = (task: RunningJobs) => {
        const { runningJobs } = this.state;

        if (!task.task_name || !task.task_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningJobs.findIndex(e => e.task_id === taskId);
            const taskItem = runningJobs.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }

            const {
                rootStore: { managerStore },
            } = this.props;
            
            managerStore.getRunningJobStatus(taskItem.task_id).then(data => {
                const endTime = new Date();
                taskItem.elapsedTime = Math.floor(Math.abs((endTime.getTime() - taskItem.startedAt) / 1000));
                if (data.status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningJobs[taskIndex] = taskItem;
                }
                if (data.status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.completion) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.completion;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
                managerStore.setCurrentJobs(runningJobs, MainTabs.Routes);
                this.setState({runningJobs});
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningJobs[taskIndex] = taskItem;
                managerStore.setCurrentJobs(runningJobs, MainTabs.Routes);
                this.setState({runningJobs});
            });
        };

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningJobs.findIndex(e => e.task_id === task.task_id);
        runningJobs[taskIndex].internalCallback = statusCallback;
        this.setState({runningJobs}, () => {
            this.setState({isRoutesDrawerOpen: true});
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    };

    fetchFoldersTree = () => {
        const {
            rootStore: { managerStore },
        } = this.props;

        managerStore.getAccountFolderTree(FOLDER_PERMISSIONS).then(data => {
            this.setState({
                treeFolders: data,
                treeFoldersClicked: [],
                treeFoldersExpanded: []
            });
        }).catch(error => {
            this.showError(error);
        });
    };

    refreshTeam = (userTeam: UserTeam | undefined) => {
        const {
            treeFoldersExpanded,
            treeFoldersClicked,
        } = this.state;

        if (userTeam) {
            this.getTeamFolders(userTeam.teamId)
                .then(
                    (
                        results: LocationFilterCallbackTypeWithAccount<Folder>
                    ) => {
                        const { data, total, accountId, accountName } = results;
                        if (data.length) {
                            // Use root node with account data
                            // if children has multiple folders
                            // otherwise user the folder data as root node
                            const treeHasRootNode = total > 1;
                            const treeFolders = {
                                id: treeHasRootNode ? accountId : data[0].id,
                                name: treeHasRootNode ? accountName : data[0].name,
                                children: treeHasRootNode ? [...data] : [],
                            };

                            this.setState({
                                treeFolders: treeFolders,
                                treeFoldersExpanded: treeHasRootNode
                                    ? [...treeFoldersExpanded, accountId]
                                    : treeFoldersExpanded,
                                treeFoldersClicked: treeHasRootNode
                                    ? [...treeFoldersClicked, accountId]
                                    : treeFoldersClicked,
                            });
                        }
                    }
                )
                .catch((error) => {
                    this.showError(
                        `${error} Location filter will be disabled.`
                    );
                });
        }
    };

    getTeamFolders = (
        teamId: string,
        folder: NavigationListItem | undefined = undefined
    ): Promise<LocationFilterCallbackTypeWithAccount<Folder>> => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const foldersTotal =
            folder && folder.folderDataTotal ? folder.folderDataTotal : -1;
        if (foldersTotal > 0 && foldersTotal <= 0) {
            return Promise.resolve({
                data: [],
                total: 0,
                accountName: '',
                accountId: '',
            });
        }

        const folderId = folder ? folder.id : '';
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        return new Promise<LocationFilterCallbackTypeWithAccount<Folder>>(
            (resolve, reject) => {
                managerStore
                    .getTeamFolders(
                        teamId,
                        folderId,
                        BUFFER_LIMIT
                    )
                    .then(
                        (
                            teamFolderResults: SearchResultsWithAccount<Folder>
                        ) => {
                            const {
                                rows: folders,
                                accountId,
                                accountName,
                                meta: { totalCount: total },
                            } = teamFolderResults;

                            const results = {
                                data: folders,
                                accountId,
                                accountName,
                                total,
                            } as LocationFilterCallbackTypeWithAccount<Folder>;

                            resolve(results);
                        }
                    )
                    .catch((error) => {
                        return reject(error);
                    });
            }
        );
    };

    showSnackbar = (
        snackbarMessage: string,
        snackbarVariant: CCSnackbarVariant
    ) => {
        this.setState({
            snackbarVariant,
            snackbarMessage,
            snackbarOpen: true,
        });
    };

    showError = (errorMessage: string | undefined) => {
        let message;
        if (errorMessage) {
            message = errorMessage;
        } else {
            message = 'Unknown Error';
        }

        this.showSnackbar(message, 'error' as CCSnackbarVariant);
    };

    showWarning = (warningMessage: string | undefined) => {
        if (!warningMessage) {
            return;
        }

        this.showSnackbar(warningMessage, 'warning' as CCSnackbarVariant);
    };

    showDialog = (dialogTitle: string, dialogMessage: string) => {
        this.setState({
            dialogTitle,
            dialogMessage,
            dialogOpen: true
        });
    };

    onSnackbarClosed = () => {
        this.setState({ snackbarOpen: false });
    };

    onDialogClosed = () => {
        this.setState({ dialogOpen: false });
    };

    onToggleSideNavBar = () => {
        const { isRoutesDrawerOpen } = this.state;
        this.setState({ isRoutesDrawerOpen: !isRoutesDrawerOpen});
    };

    onSelectFolder = (selectedFolder: EntityFolder) => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;

        if (!selectedFolder) {
            this.showWarning("The selected folder doesn't exist");
            return;
        }

        managerStore.getUserPermissions(selectedFolder.folder_id).then((response: UserPermissions) => {
            const { permissions } = response;

            const createEditPermission = permissions.includes('route_write');

            this.setState({
                showDeletePCNBtn: permissions.includes('route_delete'),
                showCreateEditPCNButtons: createEditPermission,
                gridRows: [],
                isPreviewGridMode: false,
                isCreateNewRoute: false
            });

            this.openRouteModal(selectedFolder, createEditPermission ? MODAL_TYPE.DEFAULT : MODAL_TYPE.LIST);
        }).catch((error) => {
            this.showError(error);
        });
    };

    createOtherPCN = () => {
        const { selectedFolder, isGridUpdated } = this.state;

        const openModal = () => {
            this.openRouteModal(selectedFolder as EntityFolder, MODAL_TYPE.NEW);
        };

        if (isGridUpdated) {
            this.showSaveGridModal(openModal);
            return;
        }

        openModal();
    };

    showPCNList = () => {
        const { selectedFolder, isGridUpdated } = this.state;

        const openModal = () => {
            this.openRouteModal(selectedFolder as EntityFolder, MODAL_TYPE.LIST);
        };

        if (isGridUpdated) {
            this.showSaveGridModal(openModal);
            return;
        }

        openModal();
    };

    openRouteModal = (selectedFolder: EntityFolder, pcnModalType: ModalTypeProp): void => {
        const { currentShiftLabel } = this.state;
        const defaultProperties = {
            selectedFolder,
            pcnModalType,
            isPcnModalOpen: true,
            pcnField: '',
            currentShiftLabel: currentShiftLabel !== '' ? currentShiftLabel : this.defaultShiftLabel
        };

        if (pcnModalType === MODAL_TYPE.NEW) {
            this.setState(defaultProperties);
            return;
        }

        this.updateRouteList(selectedFolder?.folder_id).then(() => {
            this.setState(defaultProperties);
        }).catch((error) => {
            this.showError(error);
        });
    };

    onClosePCNModal = () => {
        this.setState({
            isPcnModalOpen: false,
            pcnModalType: MODAL_TYPE.DEFAULT
        });
    };

    updateRouteList = (folderId?: string): Promise<void> => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;

        const { pcnOffset } = this.state;

        const { CC_ROUTES_RECORD_LIMIT } = CC_ROUTES;

        return managerStore.getPCNList(CC_ROUTES_RECORD_LIMIT, pcnOffset, { folder_id: folderId }).catch((error) => {
            this.showError(error);
        });
    };

    convertPCNRoutesIntoListItems = memoize(
        (rows: PCNList[]): SimpleListItem[] => {
            return rows.map((item: PCNList) => {
                const {
                    folder_doc_id,
                    id,
                    value,
                    shift_label
                } = item;
                const shiftLabel = shift_label !== null ? shift_label : '';
                const data = { pcnId: id, pcnValue: value, shiftLabel };
                return new SimpleListItem(
                    `pcn_${folder_doc_id}_${id}`,
                    value, shiftLabel,
                    undefined, undefined, undefined,
                    data);
            });
        }
    );

    convertToRows = (routes: ScheduleRow[]) => {
        const {
            rootStore: { 
                managerStore: {
                    folderMarkers
                }
            }
        } = this.props;

        const { gridColumns, selectedPcn } = this.state;
        const { pcnId } = selectedPcn as EntityPCN;
        
        const markerRoute: {[key: string]: ScheduleRow } = {};
        routes.map((route) => {
            markerRoute[`${route.markerId}`.replace('marker::', '') ] = route;
        });
        
        const fields = {} as { [key:string]: string | boolean };
        gridColumns.map((columnField) => {
            const { field } = columnField;
            fields[field!] = field! in this.defaultGridValues ? this.defaultGridValues[field!] : false;
        });

        const rows = folderMarkers.map((marker, index) => {
            const {id: markerId, name: markerName, floorplanName} = marker;

            let rowItem: {[key: string]: string | boolean | number} = {};
            if (markerId in markerRoute) {
                rowItem = {...fields, ...markerRoute[markerId]};
            } else {
                rowItem = {...fields, markerId:`marker::${markerId}`, markerName, floorplanName};
            }

            for (const key in rowItem) {
                if (rowItem.hasOwnProperty(key)) {
                    // Set the right boolean for weekdays' fields
                    // Otherwise use the current field value
                    rowItem[key] = `${rowItem[key]}` === `${pcnId}` ? true : `${rowItem[key]}` === '' ? false : rowItem[key];

                }
            }
            // Set id column for grid
            rowItem = {id: index, ...rowItem};
            return rowItem;
        });

        this.setState({
            gridRows: rows,
            gridColumns: gridColumns
        });
    };

    getFolderNodes = (
        teamId: string,
        folderId: string
    ): Promise<RenderTree[]> => {
        const { LOCATION_FILTER_BUFFER_LIMIT: BUFFER_LIMIT } = REPORTS;
        const {
            rootStore: { managerStore },
        } = this.props;
        return managerStore
            .getFolderFloorplans(teamId, folderId, BUFFER_LIMIT)
            .then((results: SearchResults<Floorplan>) => {
                const {
                    rows: floorplans,
                    meta: { totalCount: totalFloorplans },
                } = results;

                if (totalFloorplans) {
                    return floorplans.map((floorplan: Floorplan) => {
                        // Add prefix to differentiate floorplans
                        // from the rest of the folder nodes
                        return {
                            id: `floorplan::${floorplan.id}`,
                            name: floorplan.name,
                        };
                    });
                } else {
                    this.showWarning(
                        'There are no folders or floorplans associated to this folder'
                    );
                    return [] as RenderTree[];
                }
            });
    };

    findNodeById = (root: RenderTree | null, id: string): RenderTree | null => {
        if (!root) return null;
    
        if (root.id === id) {
            return root;
        }
    
        if (root.children) {
            for (const child of root.children) {
                const foundNode = this.findNodeById(child, id);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
    
        return null;
    };
    
    findChildrenById = (root: RenderTree | null, id: string): RenderTree[] => {
        const parentNode = this.findNodeById(root, id);
        if (parentNode && parentNode.children) {
            return parentNode.children;
        }
        return [];
    };

    onFolderTreeClick = async (folderId: string): Promise<RenderTree[]> => {
        const { userTeam } = this.props;
        const { treeFoldersClicked, treeFolders } = this.state;
        
        let folderNodes = [] as RenderTree[];

        if (userTeam) {
            folderNodes = this.findChildrenById(treeFolders, folderId);

            if (folderNodes.length <= 0) {
                folderNodes = await this.getFolderNodes(userTeam.teamId, folderId);
            }
        }

        this.setState({
            treeFoldersClicked: [...treeFoldersClicked, folderId],
        });

        return folderNodes;
    };

    onFolderTreeToggle = (folderIds: string[]) => {
        this.setState({ treeFoldersExpanded: folderIds });
    };

    onGridSaved = () => {
        const {
            rootStore: { managerStore },
        } = this.props;

        const { currentPcn, selectedPcn, gridRows } = this.state;
        const { pcnId } = selectedPcn as EntityPCN;
        const parsedRows = gridRows.map((row) => {
            for (const key in row) {
                if (typeof row[key] === 'boolean') {
                    row[key] = row[key] ? Number(pcnId) : '';
                }
            }
            return row;
        });

        // UPDATE SCHEDULE
        managerStore.createScheduleRoute(parsedRows, Number(pcnId)).then((response) => {
            const { selectedFolder, runningJobs } = this.state;
            const { jobId } = response;

            const newJob: RunningJobs = {
                task_name: selectedFolder ? selectedFolder.folder_name : 'Creating route',
                task_id: jobId,
                status: 102,
                type: JobTypes.RouteCreation,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                set_to_stop: false,
                result_data: selectedFolder
            };

            runningJobs.push(newJob);
            managerStore.setCurrentJobs(runningJobs, MainTabs.Routes);
            this.startTaskWatcher(newJob);

            // Convert to grid rows after save
            this.convertToRows(gridRows as ScheduleRow[]);

            this.setState({
                isPreviewGridMode: true,
                isGridUpdated: false,
                runningJobs
            });
            this.showDialog(`Route Saved`, `Route ${currentPcn} is updated successfully`);
        }).catch((error) => {
            this.showError(error);
        });
    };

    onGridChanged = () => {
        this.setState({ isGridUpdated: true });
    };

    showSaveGridModal = (closeSaveGridModalCallback: () => void) => {
        this.setState({
            openSaveGridModal: true,
            closeSaveGridModalCallback
        });
    };

    onGridSaveModal = (type: number) => {
        const { closeSaveGridModalCallback } = this.state;

        this.setState({ isGridUpdated: false, openSaveGridModal: false }, () => {
            if (type === 1) {
                this.onGridSaved();
            } else {
                closeSaveGridModalCallback();
            }
        });
    };

    onEditRouteModal = () => {
        const {
            rootStore: { managerStore },
        } = this.props;
        const {
            selectedPcn,
            selectedFolder,
            currentShiftLabel,
            pcnField,
            isShiftLabelEdit,
            runningJobs
        } = this.state;
        const { folder_id } = selectedFolder as EntityFolder;
        const { pcnId, pcnValue } = selectedPcn as EntityPCN;
        const newShiftLabel = isShiftLabelEdit ? currentShiftLabel : undefined;
        const newPcnValue = !isShiftLabelEdit && pcnField !== '' ? pcnField : undefined;

        managerStore.editSchedulePcn(pcnId, folder_id, newPcnValue, newShiftLabel).then((response) => {
            if (!isShiftLabelEdit) {
                const { jobId, message } = response as JobCreationResult;
                const newJob: RunningJobs = {
                    task_name: `Editing ${pcnValue}`,
                    task_id: jobId,
                    status: 102,
                    type: JobTypes.PCNEdition,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                    set_to_stop: false,
                    result_data: selectedPcn
                };

                // SEND ROUTE EDITION TO JOB
                runningJobs.push(newJob);
                managerStore.setCurrentJobs(runningJobs, MainTabs.Routes);
                this.startTaskWatcher(newJob);

                this.showDialog(`Route Edited`, message);
                this.onBackToFoldersClicked();
            } else {
                this.showDialog(`Shift Label Edited`, response as string);
            }

            this.onCloseEditPCNModal();
        }).catch((error) => {
            this.showError(error);
        });
    };

    onCloseEditPCNModal = () => {
        this.setState({ isOpenEditPCNModal: false, isShiftLabelEdit: false });
    };

    richCellRenderer = (props: CustomCellRendererProps) => {
        const { classes } = this.props;
        const handleClick = () => {
            props.api.startEditingCell({
                rowIndex: props.node.rowIndex!,
                colKey: props.column!.getId(),
            });
        };
        const formattedValue = (
            props.colDef && props.colDef.refData && props.colDef.editable
        ) ? 
            props.colDef.refData[props.value]
            : props.value;
        return (
            <span>
                {
                    props.colDef && props.colDef.editable ?
                        <>
                            <IconButton aria-label="edit" onClick={handleClick} color='primary'>
                                <EditIcon />
                            </IconButton>
                            <span className={classes.editCell}>
                                {formattedValue}
                                {formattedValue !== 'None' &&
                                    <CheckBoxIcon color='primary' />
                                }
                            </span>
                        </>
                        : props.value !== '' ?
                            <IconButton aria-label="check" color='primary'>
                                <CheckIcon />
                            </IconButton>
                            : ''
                }
            </span>
        );
    };

    formatSelectionDisplayedInput = (value: string, pcnList: GridUser[]) => {
        const checkPcn = pcnList.find(e => e.value === value);
        return checkPcn ? checkPcn.label : value;
    };

    convertToColumns = (gridColumns: ColDef[]) => {
        return gridColumns.map((column) => {
            const { cellEditor } = column;
            // Convert select to checkbox in the grid cell editor
            const columnUpdated = cellEditor === 'agRichSelectCellEditor' ? {
                ...column,
                cellEditor: 'agCheckboxCellEditor'
            } : column;

            return columnUpdated;
        });
    };

    onBackToFoldersClicked = () => {
        const { isGridUpdated } = this.state;

        if (isGridUpdated) {
            this.showSaveGridModal(this.onBackToFoldersClicked);
            return;
        }

        this.setState({
            isCreateNewRoute: false,
            isPcnModalOpen: false,
            selectedFolder: undefined,
            selectedPcn: undefined,
            users: [] as SearchResultsUser[],
            currentPcn: '',
            selectedFloorplanId: undefined,
            isMapsSectionOpen: false,
            isPreviewGridMode: false,
            routeMarkersSelected: 0
        });

    };

    onJobsDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>(() => {
            const { 
                currentJobsOffset,
                runningJobs
            } = this.state;
            const totalTasks = runningJobs.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                : isLastPage ? TASKS_PAGING_LIMIT * Math.floor(totalTasks / TASKS_PAGING_LIMIT)
                    : (boundary === PageBoundary.Upper) ? 
                        currentJobsOffset+TASKS_PAGING_LIMIT :
                        currentJobsOffset-TASKS_PAGING_LIMIT;
            const nextTasks = runningJobs;
            // MANUAL OFFSET
            this.setState({
                runningJobs: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onJobClear = () => {
        const { runningJobs } = this.state;
        const pendingTasks = runningJobs.filter(e => e.status === 102);
        const {
            rootStore: { managerStore }
        } = this.props;
        managerStore.setCurrentJobs(pendingTasks, MainTabs.Routes);
        this.setState({runningJobs: pendingTasks});
    };

    onJobClick = (job: RunningJobs) => {
        if (job.status === 102 || job.status === 200) {
            return;
        }

        const {
            rootStore: { managerStore }
        } = this.props;
        managerStore.getRunningJobError(job.task_id)
            .then((response) => {
                this.showDialog(`An error occurred creating a route for ${job.task_name}`, response.error);
            }).catch((error) => {
                this.showError(
                    `Unexpected error ocurred creating route - ${error}`
                );

            });
    };

    submitRoute = () => {
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;
        const {
            gridColumns,
            currentPcn,
            selectedPcn,
            selectedFolder,
            isNewSchedule,
            currentShiftLabel
        } = this.state;

        const { folder_id } = selectedFolder as EntityFolder;
        const pcnId = selectedPcn ? selectedPcn.pcnId : '';

        this.setState({ isCreateNewRoute: false, isGridUpdated: false, pcnField: '' });

        managerStore.getFolderMarkers(folder_id).then(() => {
            managerStore.getSchedulesRoutes(folder_id, pcnId).then((routes) => {
                if (isNewSchedule) {
                    // CREATE NEW ROUTE
                    managerStore.createSchedulePcn(currentPcn, folder_id, currentShiftLabel).then((selectedPcn: EntityPCN) => {
                        const fields = {} as { [key:string]: string | boolean };
                        const columns = gridColumns.map((columnField) => {
                            const { field } = columnField;

                            fields[field!] = field! in this.defaultGridValues ? this.defaultGridValues[field!] : false;

                            if(!Object(columnField).hasOwnProperty('editable')) {
                                columnField.editable = true;
                            }

                            if (Object(columnField).hasOwnProperty('editable') && columnField['editable']) {
                                columnField.editable = true;
                            }

                            return columnField;
                        });

                        const rows = managerStore.folderMarkers.map((marker, index) => {
                            const {id: markerId, name: markerName, floorplanName} = marker;

                            return {id: index, ...fields, markerId:`marker::${markerId}`, markerName, floorplanName};
                        });

                        this.setState({
                            isCreateNewRoute: true,
                            gridRows: rows,
                            gridColumns: columns,
                            selectedPcn
                        });

                        this.showSnackbar(
                            `${currentPcn} - ${ROUTE_CREATED_SUCCESFULLY}`,
                            'success' as CCSnackbarVariant
                        );
                    }).catch((error) => {
                        this.showError(error);
                    });
                } else {
                    // EDIT ROUTE
                    this.convertToRows(routes);
                    const columns = gridColumns.map((columnField) => {
                        if (Object(columnField).hasOwnProperty('editable') && columnField['editable']) {
                            columnField.editable = false;
                        }

                        return columnField;
                    });
                    this.setState({
                        gridColumns: columns
                    });
                }

                this.setState({
                    isPreviewGridMode: false,
                    isRoutesDrawerOpen: false,
                    isPcnModalOpen: false,
                    routeMarkersSelected: routes.length
                });
            }).catch((error) => {
                this.showError(error);
            });
        }).catch((error) => {
            this.showError(error);
        });
    };

    setRouteValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pcn = `${event.target.value}`.replace('@', '');
        this.setState({ pcnField: pcn });
    };

    setShiftLabel = (event: SelectChangeEvent) => {
        const shiftLabel = event.target.value;
        this.setState({ currentShiftLabel: shiftLabel });
    };

    selectExistingRoute = (selectedItem: SimpleListItem) => {
        const {label: pcn, secondaryLabel: shiftLabel, data} = selectedItem;
        this.setState({
            currentPcn: pcn,
            selectedPcn: data as EntityPCN | undefined,
            currentShiftLabel: shiftLabel ? shiftLabel as string : '',
            isNewSchedule: false
        }, () => {
            this.submitRoute();
        });
    };

    onCreateNewRoute = () => {
        const { pcnField } = this.state;

        this.setState({
            currentPcn: pcnField,
            isNewSchedule: true
        }, () => {
            this.submitRoute();
        });
    };

    onShowMarkersClicked = () => {
        const {
            rootStore: {
                managerStore: {
                    folderFloorplans
                }
            }
        } = this.props;

        const floorplanItems = folderFloorplans.map( (floorplan: Floorplan) => {
            const { id, name } = floorplan;
            return new SimpleListItem(id, name);
        });

        this.setState({
            isMapsSectionOpen: true,
            floorplanList: floorplanItems
        });
        
    };

    onSelectFloorplan = (selectedItem: SimpleListItem) => {
        const { id: floorplanId } = selectedItem;
        const {
            rootStore: { 
                managerStore
            }
        } = this.props;

        managerStore.getMarkersCoordinates(floorplanId).then((markers) => {
            managerStore.getFloorplanImage(floorplanId).then((image) => {
                this.setState({ selectedFloorplanId: floorplanId as string | undefined });
                // Remove previous map instance
                this.map?.remove();
                // Initialize map instance
                this.map = L.map('map', {
                    crs: L.CRS.Simple,
                    minZoom: -1,
                });
                this.renderFloorplanMap(this.map, image, markers);
            }).catch((error) => {
                this.showError(error);
            });
        }).catch((error) => {
            this.showError(error);
        });
    };

    onShowGridClicked = () => {
        this.setState({
            isMapsSectionOpen: false,
            selectedFloorplanId: undefined
        });
    };

    renderFloorplanMap = (map: L.Map, image: string, markerList: PCNMarkerCoords[]) => {
        const canvas = L.DomUtil.create('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        const paintImage = new Image();
        paintImage.src = `data:image/png;base64,${image}`;

        // Render Map
        paintImage.onload = () => {
            const canvasWidth = paintImage.width;
            const canvasHeight = paintImage.height;
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            context.drawImage(paintImage, 0, 0, canvasWidth, canvasHeight);

            const bounds = [
                [0, 0],
                [canvasHeight * -1, canvasWidth],
            ] as LatLngBoundsExpression;

            const image = L.imageOverlay(canvas.toDataURL(), bounds);
            image.addTo(map);
            map.fitBounds(bounds);
            map.setView( [canvasHeight/2 * -1, canvasWidth/2], 1);

            // Render Markers
            const myIcon = L.divIcon({ className: 'marker-icon' });
            markerList.map((marker) => {
                const { coordinates, name } = marker;
                const locations = coordinates.split(',');
                const position: LatLngExpression = [parseFloat(locations[1]) * -1, parseFloat(locations[0])];
                const markerMap = L.marker(position, { icon: myIcon });
                markerMap.addTo(map);
                markerMap.bindPopup(name);
                markerMap.bindTooltip(name);
            });
        };
    };

    generateTodayRoutePDFPage = () => {
        const {
            rootStore: { 
                managerStore: { 
                    schedulesRoutesRows,
                    getPCNFilterList
                }
            }
        } = this.props;
        const { currentPcn, selectedFolder } = this.state;
        const weekdays = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday'
        ];
        // The margin top/bottom for the PDF page
        const verticalMargin = 20;

        // Coordinates values are the absolute measures that jsPDF uses for PDF file creation
        // this values starting as X and Y axis positions (X=0 and Y=0 means the top left corner of page)
        // Check https://github.com/MrRio/jsPDF for more info
        let verticalCoordinate = verticalMargin;

        // The margin right for the PDF file
        const marginHorizontal = 20;

        function getNextVerticalCoordinate(
            rowsAdded = 1,
            nextLineIncrement = 6,
            additionalLineIncrement = 0
        ): number {
            const currentValue = verticalCoordinate;
            verticalCoordinate +=
                rowsAdded * nextLineIncrement + additionalLineIncrement;
            return currentValue;
        }

        const currentDate = dayjs();
        const weekDay = weekdays[currentDate.day()];
        const currentDayRoute = schedulesRoutesRows.filter((row) => {
            return weekDay in row;
        }).map((item) => {
            const { markerId, markerName, floorplanName } = item;
            return {
                markerId,
                markerName,
                floorplanName,
                [weekDay]: item[weekDay]
            };
        });

        if (!currentDayRoute.length) {
            this.showWarning(
                `There is no ${currentPcn} route for today`
            );
            return;
        }

        let user = 'UNASSIGNED';
        let folder = 'UNKNOWN';
        let shiftLabel = 'Morning';
        getPCNFilterList({ value: currentPcn, folder_id: selectedFolder?.folder_id }).then(({ rows }) => {
            if (!rows.length) {
                this.showWarning(`There are no results related with this route number ${currentPcn}`);
                return;
            }

            const pcnRow = rows[0];
            if ('email' in pcnRow) {
                user = pcnRow['email'] as string;
            }
            folder = pcnRow['folder_name'] as string;
            shiftLabel = pcnRow['shift_label'] as string;

            const markersGroups = splitList(currentDayRoute, 11);
            const pdfDocument = new jsPDF({ format: 'letter' });
            const routeTitle = CC_ROUTES_NAME ? `${CC_ROUTES_NAME} Route: ${currentPcn}` : `Route: ${currentPcn}`;

            for (let i = 0; i < markersGroups.length; i++) {
                if (i > 0) {
                    pdfDocument.addPage();
                    verticalCoordinate = verticalMargin;
                }

                pdfDocument.addImage(ccLogoBottom, 'PNG', 170, 5, 30, 18);

                if (CC_ROUTES_NAME in PDF_LOGO) {
                    pdfDocument.addImage(PDF_LOGO[CC_ROUTES_NAME], 'PNG', 170, 28, 30, 14);
                }

                pdfDocument
                    .setFont('Helvetica', 'bold')
                    .setFontSize(18)
                    .text(`Today's Route`, marginHorizontal, getNextVerticalCoordinate(2))
                    .setFont('Helvetica', 'normal')
                    .setFontSize(12)
                    .text(
                        [
                            currentDate.format('dddd, MMMM D, YYYY'),
                            routeTitle,
                            `School: ${folder} - ${shiftLabel}`,
                            `User Assigned: ${user}`
                        ],
                        marginHorizontal,
                        getNextVerticalCoordinate(5)
                    )
                    .setFontSize(18)
                    .text('Markers', marginHorizontal, getNextVerticalCoordinate(2));

                markersGroups[i].map(({markerName, floorplanName}) => {
                    pdfDocument
                        .setFontSize(14)
                        .setTextColor('#000')
                        .rect(188, verticalCoordinate - 4, 8, 8)
                        .text(markerName, marginHorizontal, getNextVerticalCoordinate())
                        .setFontSize(12)
                        .setTextColor('#666')
                        .text(floorplanName, marginHorizontal, getNextVerticalCoordinate(2));
                });
            }

            pdfDocument.save(
                `cc-route-${currentPcn}-${currentDate.format('L')}.pdf`
            );
        }).catch((error) => {
            this.showError(error);
            return;
        });
    };

    onPCNBufferOverBoundary = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise((resolve) => {
            const {
                rootStore: {
                    managerStore: {
                        pcnRoutesTotal
                    }
                }
            } = this.props;

            const {
                pcnOffset: currentOffset,
                selectedFolder
            } = this.state;

            const { CC_ROUTES_RECORD_LIMIT } = CC_ROUTES;

            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage ===
                Math.ceil(pcnRoutesTotal / CC_ROUTES_PER_PAGE) - 1;
            let newOffset = isFirstPage
                ? 0
                : isLastPage
                    ? CC_ROUTES_RECORD_LIMIT * Math.floor(pcnRoutesTotal / CC_ROUTES_RECORD_LIMIT)
                    : boundary === PageBoundary.Upper
                        ? currentOffset + CC_ROUTES_RECORD_LIMIT
                        : currentOffset - CC_ROUTES_RECORD_LIMIT;

            // If we reached exactly the end of the buffer we go one page back
            if (isLastPage && newOffset === pcnRoutesTotal) {
                newOffset -= CC_ROUTES_RECORD_LIMIT;
            }

            this.setState({
                pcnOffset: newOffset,
                pcnPage: nextPage,
            }, () => {
                
                this.updateRouteList(selectedFolder?.folder_id);
            });

            resolve();
        });
    };

    onOpenEditRoutesModal = (isShiftLabelEdit: boolean) => {
        const { currentPcn } = this.state;
        this.setState({
            isOpenEditPCNModal: true,
            pcnField: currentPcn,
            isShiftLabelEdit: isShiftLabelEdit
        });
    };

    renderRoutesList = (folderPCNList: SimpleListItem[]) => {
        const {
            rootStore: {
                managerStore: {
                    pcnRoutesTotal,
                    isPcnRoutesLoading
                }
            },
            classes
        } = this.props;
        const { pcnOffset, pcnModalType } = this.state;

        return (
            <>
                {
                    pcnModalType === MODAL_TYPE.DEFAULT && !folderPCNList.length ? ''
                        : <div data-testid="pcn-list">
                            <Typography
                                className={classes.pcnModalTitle}
                                variant="h5"
                            >
                                Select a { CC_ROUTES_NAME } route
                            </Typography>
                            <SimpleListPagination
                                className={classes.pcnList}
                                items={folderPCNList}
                                onListItemClick={this.selectExistingRoute}
                                rowsPerPage={CC_ROUTES_PER_PAGE}
                                onPageOverBoundary={this.onPCNBufferOverBoundary}
                                totalItems={pcnRoutesTotal}
                                offset={pcnOffset}
                                isLoading={isPcnRoutesLoading}
                            />
                        </div>
                }
            </>
        );
    };

    renderShiftLabelDropdown = (shiftLabel: string) => {
        return (
            <FormControl fullWidth>
                <InputLabel>Shift Label</InputLabel>
                <Select
                    value={shiftLabel}
                    label="Shift Label"
                    onChange={this.setShiftLabel}
                    data-testid="shiftlabel-select"
                >
                    { 
                        this.shiftLabels.map((item, index) => {
                            const { label, value } = item;
                            return (
                                <MenuItem value={value} key={`${label}-${index}`}>{label}</MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
        );
    };

    onOpenDeleteRouteModal = () => {
        this.setState({ isOpenDeletePCNModal: true });
    };

    onDeleteModal = (type: number) => {
        if (type === 1) {
            const {
                rootStore: {
                    managerStore
                }
            } = this.props;

            const { selectedPcn, selectedFolder, runningJobs } = this.state;
            const { folder_id } = selectedFolder as EntityFolder;
            const { pcnId, pcnValue } = selectedPcn as EntityPCN;

            managerStore.deleteSchedulePcn(pcnId, folder_id).then((response) => {
                const { jobId, message } = response;
                const newJob: RunningJobs = {
                    task_name: `Deleting ${pcnValue}`,
                    task_id: jobId,
                    status: 102,
                    type: JobTypes.PCNDeletion,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                    set_to_stop: false,
                    result_data: selectedPcn
                };

                // SEND ROUTE DELETION TO JOB
                runningJobs.push(newJob);
                managerStore.setCurrentJobs(runningJobs, MainTabs.Routes);
                this.startTaskWatcher(newJob);

                this.setState({ isOpenDeletePCNModal: false, isGridUpdated: false });
                this.onBackToFoldersClicked();
                this.showDialog(`Route Deleted`, `Deleting Route: ${pcnValue} - ${message}`);
            }).catch((error) => {
                this.showError(error);
                return;
            });
        } else {
            this.setState({ isOpenDeletePCNModal: false });
        }
    };

    public render() {
        const {
            isRoutesDrawerOpen,
            snackbarVariant,
            snackbarMessage,
            snackbarOpen,
            dialogOpen,
            dialogTitle,
            dialogMessage,
            treeFolders,
            treeFoldersClicked,
            treeFoldersExpanded,
            gridRows,
            isCreateNewRoute,
            gridColumns,
            excludeColumnForFill,
            selectedFolder,
            isPreviewGridMode,
            runningJobs,
            currentJobsOffset,
            isPcnModalOpen,
            currentPcn,
            currentShiftLabel,
            isMapsSectionOpen,
            floorplanList,
            selectedFloorplanId,
            isNewSchedule,
            pcnModalType,
            pcnField,
            openSaveGridModal,
            selectedPcn,
            isShiftLabelEdit,
            isOpenEditPCNModal,
            isOpenDeletePCNModal,
            showDeletePCNBtn,
            showCreateEditPCNButtons,
            routeMarkersSelected
        } = this.state;

        const {
            rootStore: {
                managerStore: {
                    isRoutesTabLoading,
                    isFolderFloorplansLoading,
                    isFolderMarkersLoading,
                    isTeamFoldersLoading,
                    isPcnMapLoading,
                    pcnRoutes
                }
            },
            classes
        } = this.props;

        const isTreeLoading = isFolderFloorplansLoading || isTeamFoldersLoading;
        const folderPCNList =
            pcnRoutes ?
                this.convertPCNRoutesIntoListItems(pcnRoutes)
                : [];

        const disableSavePCNChanges = isShiftLabelEdit ? selectedPcn?.shiftLabel === currentShiftLabel : (pcnField.trim() === '' || pcnField.trim() === currentPcn);

        return (
            <>
                <SideNavbar
                    variant="persistent"
                    isDrawerOpen={isRoutesDrawerOpen}
                    collapsedArea={
                        <div
                            className={`${classes.navbarIconContainer} ${classes.navbarIconContainerSmall}`}
                        >
                            <ArticleIcon className={classes.navbarIcon} />
                        </div>
                    }
                    onExpanderClick={this.onToggleSideNavBar}
                >
                    <div className={`${classes.contentContainer} ${classes.navbarContent}`}>
                        <div className={classes.navbarTitleContainer}>
                            <div className={classes.navbarIconContainer}>
                                <ArticleIcon className={classes.navbarIcon} />
                            </div>
                            <Typography
                                className={classes.navbarTitle}
                                variant="subtitle1"
                            >
                                { CC_ROUTES_NAME } Routes Options
                            </Typography>
                        </div>
                        <Divider className={classes.divider} />
                        <div>
                            <Button
                                className={classes.routeNavButton}
                                onClick={this.onShowGridClicked}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!isMapsSectionOpen}
                            >
                                <CalendarViewWeekIcon/> Show Grid
                            </Button>
                            <Button
                                className={classes.routeNavButton}
                                onClick={this.onShowMarkersClicked}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!selectedFolder || isMapsSectionOpen}
                            >
                                <PlaceIcon/> Show Markers
                            </Button>
                            { 
                                isMapsSectionOpen ?
                                    <>
                                        <Divider className={classes.divider} />
                                        <Typography
                                            className={classes.navbarComponentTitle}
                                            variant="button"
                                        >
                                            FLOORPLANS
                                        </Typography>
                                        <SimpleList
                                            className={classes.floorplanList}
                                            items={floorplanList}
                                            onListItemClick={this.onSelectFloorplan}
                                            selectedItemId={selectedFloorplanId}
                                        />
                                    </>
                                    : ''
                            }
                        </div>
                        <Divider className={classes.divider} />
                        <Button
                            className={classes.routeNavButton}
                            onClick={this.generateTodayRoutePDFPage}
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={isNewSchedule || isPreviewGridMode || !selectedFolder}
                        >
                            <PictureAsPdf /> Today`s Route
                        </Button>
                        <Button
                            className={classes.routeNavButton}
                            onClick={this.onBackToFoldersClicked}
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!selectedFolder}
                        >
                            <ApartmentIcon/> Back to Buildings
                        </Button>
                        {
                            (runningJobs && (runningJobs.length > 0)) &&
                            <>
                                <div className={`${classes.navbarTitleContainer} ${classes.topMargin}`}>
                                    <div className={classes.navbarIconContainer}>
                                        <HourglassBottom className={classes.navbarIcon} />
                                    </div>
                                    <Typography
                                        className={classes.navbarTitle}
                                        variant="subtitle1"
                                    >
                                        Routes Processing
                                    </Typography>
                                </div>
                                <Divider className={classes.divider} />
                                <RunningJobsList
                                    className={classes.tasksContainerSize}
                                    classes={{
                                        buttonClear: classes.routeNavButton
                                    }}
                                    tasks={runningJobs}
                                    rowsPerPage={TASKS_ROWS_PER_PAGE}
                                    taskItemsOffset={currentJobsOffset}
                                    onJobsDataPageOverBoundary={this.onJobsDataPageOverBoundaryReached}
                                    onJobClear={this.onJobClear}
                                    onJobClick={this.onJobClick}
                                />
                            </>
                        }
                    </div>
                </SideNavbar>
                {
                    selectedFolder ?
                        isMapsSectionOpen ?
                            <div className={classes.mapContainer}>
                                <Typography
                                    className={classes.bodyTitle}
                                    variant="h4"
                                >
                                    Select a Floorplan from {selectedFolder.folder_name}
                                </Typography>
                                <div className={classes.mapWrapper}>
                                    <div id="map"></div>
                                </div>
                            </div>
                            :
                            <div className={`${classes.dataGridContainer} ${isRoutesDrawerOpen ? 'navbar-opened' : ''}`}>
                                {
                                    isCreateNewRoute ?
                                        <Typography
                                            className={classes.bodyTitle}
                                            variant="h4"
                                        >
                                            { 
                                                isPreviewGridMode ?
                                                    `New Schedule created for ${selectedFolder?.folder_name} - Preview ${CC_ROUTES_NAME} Route: ${currentPcn}, ${currentShiftLabel}`
                                                    : `Building: ${selectedFolder?.folder_name} - New ${CC_ROUTES_NAME} Route: ${currentPcn}, ${currentShiftLabel}`
                                            }
                                        </Typography>
                                        :
                                        <Typography
                                            className={classes.bodyTitle}
                                            variant="h4"
                                        >
                                            Building: {selectedFolder.folder_name} {currentPcn !== '' && !isPcnModalOpen ? `- ${CC_ROUTES_NAME} Route: ${currentPcn}, ${currentShiftLabel}` : ''}
                                        </Typography>
                                }
                                <div className={classes.pcnActionsContainer}>
                                    {
                                        routeMarkersSelected ? 
                                            <span className={classes.totalMarkersSelected}>Markers Selected: {routeMarkersSelected}</span>
                                            : ''
                                    }
                                    <Button
                                        color="primary"
                                        onClick={this.showPCNList}
                                        disabled={!folderPCNList.length}
                                        startIcon={<ListIcon/>}
                                    >
                                        Show routes
                                    </Button>
                                    {
                                        showCreateEditPCNButtons ?
                                            <Button
                                                color="primary"
                                                onClick={this.createOtherPCN}
                                                disabled={isCreateNewRoute && !isPreviewGridMode}
                                                startIcon={<AddIcon />}
                                            >
                                                Create new route
                                            </Button>
                                            : ''
                                    }
                                    {
                                        !selectedPcn || isCreateNewRoute ? ''
                                            : <>
                                                {
                                                    showCreateEditPCNButtons ?
                                                        <>
                                                            <Button
                                                                className={classes.gridActionsBtn}
                                                                onClick={() => this.onOpenEditRoutesModal(true)}
                                                                data-testid="edit-shiftlabel-button"
                                                            >
                                                                <EditCalendarIcon /> Edit Shift Label
                                                            </Button>
                                                            <Button
                                                                className={classes.gridActionsBtn}
                                                                onClick={() => this.onOpenEditRoutesModal(false)}
                                                                data-testid="edit-pcn-button"
                                                            >
                                                                <EditCalendarIcon /> Edit route
                                                            </Button>
                                                        </>
                                                        : ''
                                                }
                                                {
                                                    showDeletePCNBtn ?
                                                        <Button
                                                            className={classes.gridActionsBtn}
                                                            onClick={() => this.onOpenDeleteRouteModal()}
                                                            data-testid="delete-pcn-button"
                                                        >
                                                            <DeleteIcon /> Delete route
                                                        </Button>
                                                        : ''

                                                }
                                            </>
                                    }
                                </div>
                                <Divider className={classes.divider} />
                                <AgGridList 
                                    className={classes.gridWrapper}
                                    isLoading={isFolderMarkersLoading || isRoutesTabLoading}
                                    enableEditMode={true}
                                    excludeColumnFillIds={excludeColumnForFill}
                                    startAsEditableMode={isCreateNewRoute && !isPreviewGridMode}
                                    rows={gridRows}
                                    columns={gridColumns}
                                    onGridSaved={this.onGridSaved}
                                    onGridChanged={this.onGridChanged}
                                    rowSelection='multiple'
                                    enableRangeSelection={true}
                                    enableFillHandle={true}
                                    suppressClearOnFillReduction={true}
                                    pagination={true}
                                    paginationPageSize={100}
                                    paginationPageSizeSelector={[25, 50, 100]}
                                    rowHeight={50}
                                />
                            </div>
                        :
                        <div className={classes.treeContainer}>
                            <Typography
                                className={classes.bodyTitle}
                                variant="h5"
                            >
                                Select a Building to Check or Edit
                            </Typography>
                            <FolderTree
                                folderTreeData={treeFolders}
                                foldersClicked={treeFoldersClicked}
                                foldersExpanded={treeFoldersExpanded}
                                onFolderTreeClick={this.onFolderTreeClick}
                                onSelectFolder={this.onSelectFolder}
                                onFolderTreeToggle={this.onFolderTreeToggle}
                                isLoading={isTreeLoading}
                            />
                        </div>
                }
                <SimpleModal
                    className={classes.pcnModal}
                    open={isPcnModalOpen}
                    buttonOkLabel=""
                    onModalResult=""
                    footer={
                        <div className={classes.pcnModalFooter}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={pcnModalType === MODAL_TYPE.DEFAULT ? this.onBackToFoldersClicked : this.onClosePCNModal}
                            >
                                Cancel
                            </Button>
                            {
                                pcnModalType === MODAL_TYPE.LIST ? ''
                                    : <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onCreateNewRoute}
                                        disabled={pcnField.trim() === ''}
                                        data-testid="create-pcn-button"
                                    >
                                        Create
                                    </Button>
                            }
                        </div>
                    }
                >
                    <div className={classes.pcnModalContent}>
                        {
                            pcnModalType === MODAL_TYPE.NEW ? '' : this.renderRoutesList(folderPCNList)
                        }
                        {
                            pcnModalType === MODAL_TYPE.LIST ? ''
                                : <>
                                    <Typography
                                        className={classes.pcnModalTitle}
                                        variant="h5"
                                    >
                                        Create a new { CC_ROUTES_NAME } route
                                    </Typography>
                                    <div className={classes.pcnModalContainer}>
                                        <TextField
                                            className={classes.pcnInput}
                                            label="Enter the route"
                                            variant="outlined"
                                            onChange={this.setRouteValue}
                                            placeholder="Enter the route"
                                        />
                                        { this.renderShiftLabelDropdown(currentShiftLabel) }
                                    </div>
                                </>
                        }
                    </div>
                </SimpleModal>
                <SimpleModal
                    className={classes.pcnModal}
                    open={isOpenEditPCNModal}
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    footer={
                        <div className={classes.pcnModalFooter}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.onCloseEditPCNModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onEditRouteModal}
                                disabled={disableSavePCNChanges}
                                data-testid="save-pcn-button"
                            >
                                Save Changes
                            </Button>
                        </div>
                    }
                >
                    <>
                        <Typography
                            className={classes.pcnModalTitle}
                            variant="h5"
                        >
                            Edit { CC_ROUTES_NAME } Route: {currentPcn}
                        </Typography>
                        <div className={classes.pcnModalContainer}>
                            {
                                isShiftLabelEdit ? this.renderShiftLabelDropdown(currentShiftLabel)
                                    : <TextField
                                        className={classes.pcnInput}
                                        label="PCN"
                                        variant="outlined"
                                        onChange={this.setRouteValue}
                                        placeholder="Enter the route"
                                        value={pcnField}
                                        data-testid="edit-pcn-input"
                                    />
                            }
                        </div>
                    </>
                </SimpleModal>
                <CCSnackbar
                    className={classes.snackbar}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transition="right"
                    open={snackbarOpen}
                    onClose={this.onSnackbarClosed}
                />
                <SimpleDialog
                    open={dialogOpen}
                    titleText={dialogTitle}
                    buttonCancelLabel=""
                    contentText={dialogMessage}
                    onDialogResult={this.onDialogClosed}
                    disableTransition={true}
                />
                <SimpleModal
                    className={classes.modal}
                    open={openSaveGridModal}
                    onModalResult={this.onGridSaveModal}
                    buttonOkLabel="Save before continue"
                    buttonCancelLabel="Continue without saving"
                >
                    Do you want to save the pending grid changes, before leaving?
                </SimpleModal>
                <SimpleModal
                    className={classes.modal}
                    open={isOpenDeletePCNModal}
                    onModalResult={this.onDeleteModal}
                    buttonOkLabel="Delete"
                    buttonCancelLabel="Cancel"
                >
                    <>
                        <Typography
                            variant="h5"
                        >
                            Delete { CC_ROUTES_NAME } route
                        </Typography>
                        <div className={classes.pcnModalContainer}>
                            This is a permanent delete for the route - <strong>{currentPcn}</strong>.<br/>
                            Are you sure you want to permanently delete this route: <strong>{currentPcn}</strong>?
                        </div>
                    </>
                </SimpleModal>
                <CCSpinner
                    className={classes.spinner}
                    loading={isRoutesTabLoading || isFolderMarkersLoading || isPcnMapLoading}
                    overlayVisible={true}
                    size={100}
                />
            </>
        );
    }

}

const MUIComponent = withStyles(styles)(RoutesTab);
export { MUIComponent as RoutesTab };
